import React, { Component } from 'react';

class SingleMovieHeader extends Component {
  render() {
    return (
      <header className="singlemovie-header mb-4">
        <img
          src={this.props.artwork}
          alt={this.props.title}
          width="100%"
          height="100%"
          className="img-responsive"
        />
      </header>
    )
  }
}

export default SingleMovieHeader;