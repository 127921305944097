import React, { Component } from 'react';
import $ from 'jquery';

class Subscription extends Component {

  componentDidMount = () => {

    $('.btn-number').click(function(e){
    e.preventDefault();

    var fieldName = $(this).attr('data-field');
    var type      = $(this).attr('data-type');
    var input = $("input[name='"+fieldName+"']");
    var currentVal = parseInt(input.val());
    if (!isNaN(currentVal)) {
      if(type === 'minus') {

          if(currentVal > input.attr('min')) {
              input.val(currentVal - 1).change();
          }
          if(parseInt(input.val()) === input.attr('min')) {
              $(this).attr('disabled', true);
          }

      } else if(type === 'plus') {

          if(currentVal < input.attr('max')) {
              input.val(currentVal + 1).change();
          }
          if(parseInt(input.val()) === input.attr('max')) {
              $(this).attr('disabled', true);
          }

      }
    } else {
        input.val(0);
    }
  });
$('.input-number').focusin(function(){
   $(this).data('oldValue', $(this).val());
});
$('.input-number').change(function() {

    var minValue =  parseInt($(this).attr('min'));
    var maxValue =  parseInt($(this).attr('max'));
    var valueCurrent = parseInt($(this).val());

    var name = $(this).attr('name');
    if(valueCurrent >= minValue) {
        $(".btn-number[data-type='minus'][data-field='"+name+"']").removeAttr('disabled')
    } else {
        alert('Sorry, the minimum value was reached');
        $(this).val($(this).data('oldValue'));
    }
    if(valueCurrent <= maxValue) {
        $(".btn-number[data-type='plus'][data-field='"+name+"']").removeAttr('disabled')
    } else {
        alert('Sorry, the maximum value was reached');
        $(this).val($(this).data('oldValue'));
    }

});

$(".input-number").keydown(function (e) {
      // Allow: backspace, delete, tab, escape, enter and .
      if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
            // Allow: Ctrl+A
          (e.keyCode === 65 && e.ctrlKey === true) ||
            // Allow: home, end, left, right
          (e.keyCode >= 35 && e.keyCode <= 39)) {
                // let it happen, don't do anything
                return;
      }
      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
          e.preventDefault();
      }
  });

}

  render() {
    return (
      <div className="col-md-4 movie__ticket-form">
        <div className="input-group">
          <p>Select City:
            <select class="custom-select" name="city">
              <option selected disabled>Select City</option>
              <option value="1">Lagos</option>
              <option value="2">Ibadan</option>
              <option value="3">Ilorin</option>
            </select>
          </p>
        </div>

        <div className="input-group">
          <p>Select Cinema:
            <select class="custom-select" name="movie_location">
              <option selected disabled>Select Cinema</option>
              <option value="1">Filmhouse, Lekki</option>
              <option value="2">Ozone Cinema</option>
              <option value="3">Maryland Mall</option>
              <option value="3">Silverbird Galleria</option>
            </select>
          </p>
        </div>

        <div className="input-group">
          <div className="row">
            <div className="col-md-6">
              <p>Preferred Date:
                <select class="custom-select" name="preferred_date">
                  <option selected disabled>Select Date</option>
                  <option value="1">Friday, 23/03/2019</option>
                  <option value="2">Saturday, 24/03/2019</option>
                  <option value="3">Monday, 26/03/2019</option>
                </select>
              </p>
            </div>
            <div className="col-md-6">
              <p>Preferred Time:
                <select class="custom-select" name="preferred_time">
                  <option selected disabled>Select Time</option>
                  <option value="1">10:30 AM</option>
                  <option value="2">11:20 AM</option>
                  <option value="3">02:00 PM</option>
                  <option value="3">02:15 PM</option>
                </select>
              </p>
            </div>
          </div>
        </div>

        <div className="input-group">
          <div className="row mb-4">
            <div className="col-md-12">Choose Ticket Type</div>
          </div>
          <div className="row">
            <div className="col-md-9 ticket-type">Premium + Popcorn + Coke: N2,500</div>
            <div className="col-md-3">
              <select class="custom-select" name="movie_location">
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
            </div>
          </div>
        </div>

        <div className="input-group">
          <div className="row">
            <div className="col-md-9 ticket-type">Premium + Popcorn + Coke: N2,500</div>
            <div className="col-md-3">
              <select class="custom-select" name="movie_location">
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
            </div>
          </div>
        </div>

        <div className="input-group">
          <div className="row">
            <div className="col-md-9 ticket-type">Premium + Popcorn + Coke: N2,500</div>
            <div className="col-md-3">
              <select class="custom-select" name="movie_location">
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
            </div>
          </div>
        </div>

        <div className="input-group">
          <div className="row">
            <div className="col-md-8">
              <p>Ticket Type:
                <select class="custom-select" name="movie_location">
                  <option disabled selected>---------</option>
                  <option value="regular">Regular</option>
                  <option value="premium_poporn_coke">Premium + Popcorn + Coke: N2,500</option>
                  <option value="vip_adult_poporn_coke">VIP Adult + Popcorn + Coke: N2,500</option>
                </select>
              </p>
            </div>
            <div className="col-md-4">
              <p>Qty:
                <div className="input-group">
                  <span className="input-group-btn">
                    <button type="button" className="btn btn-default btn-number" disabled="disabled" data-type="minus" data-field="quant[1]">
                      <span className="glyphicon glyphicon-minus"></span>
                    </button>
                  </span>
                  <input type="text" name="quant[1]" class="form-control input-number" value="1" min="1" max="10" />
                    <span className="input-group-btn">
                      <button type="button" className="btn btn-default btn-number" data-type="plus" data-field="quant[1]">
                        <span className="glyphicon glyphicon-plus"></span>
                      </button>
                    </span>
                </div>
              </p>
            </div>
          </div>
        </div>

        <div className="input-group movie__total">
          <span className="total">Total</span>
          <span className="price"><span className="naira_sign">N</span> 0</span>
        </div>

        <div className="input-group">
          <button className="ticket-button block-btn">Submit</button>
        </div>
      </div>
    )
  }
}

export default Subscription;