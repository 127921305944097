import { REGISTER_PENDING, REGISTER_SUCCESS, REGISTER_FAILED } from '../actions/types';
//GET_MOVIES_FILTER_PENDING, GET_MOVIES_FILTER_SUCCESS, GET_MOVIES_FILTER_FAILED,
const INITIAL_STATE = {
    fetching: false, fetched: false,
    isRegistered: false, user: null, error: null,
};

const register = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REGISTER_PENDING: {
            return { ...state, fetching: true, fetched: false };
        }
        case REGISTER_SUCCESS: {
            return {
                ...state, fetching: false, fetched: true, isRegistered: true,
                user: action.payload, error: null,
            };
        }
        case REGISTER_FAILED: {
            return { ...state, fetching: false, fetched: false, isRegistered: false, error: action.payload };
        }
        default: return { ...state }
    }
};
export default register;