import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getMovies } from '../../redux/actions/movies';
import MovieItem from '../../smarts/MovieItem';

class AllMoviesList extends Component {
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(getMovies());
    }
    render() {
        let { movies } = this.props

        let toRender = movies.map(function (item, index, arr) {
            return <MovieItem movie={item} key={item.id} />
        })

        return (
            <div>
                <div className="container movies-section">
                    <div className="row">
                        <div className="section-title">Movies at the Cinema</div>
                    </div>

                    <div className="row"></div>
                </div>
                <div className="container pt-5">
                    <div className="row movies">{toRender}</div>

                    <div className="row"></div>
                </div>
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        movies: state.movies.movies,
    }
    //const { movies } = state.movies
}

export default connect(mapStateToProps)(AllMoviesList)