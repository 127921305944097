import React, { Component } from 'react';
import Footer from './includes/Footer';
import FooterTop from './includes/FooterTop';
import Nav from './includes/Nav';

class Help extends Component {
  componentDidMount = () => {
		var acc = document.getElementsByClassName('accordion');
		var i;

		for (i = 0; i < acc.length; i++) {
			acc[i].addEventListener('click', function() {
				this.classList.toggle('active');
				var panel = this.nextElementSibling;
				if (panel.style.maxHeight) {
					panel.style.maxHeight = null;
				} else {
					panel.style.maxHeight = panel.scrollHeight + 'px';
				}
			});
		}
	};

  render() {
    return (
      <div>
        <Nav />
        <main style={{ marginTop: "10rem" }}>
          <div className="container-fluid">
          <div className="faq-heading offset-sm-2 col-sm-8" style={{ paddingLeft: "3rem" }}>
            <h1>FAQs</h1>
          </div>
            <div className="accordionBody">
              <div className="offset-sm-2 col-sm-8">
                <button class="accordion">
                  How do I get my tickets at the cinema?
                </button>
                <div class="panel">
                  <p className="pt-2">
                    Kindly provide to the attendant the e-ticket sent to
                    your email or phone number used at the point of purchase
                  </p>
                </div>

                <button class="accordion">
                  I have been debited where is my ticket?
                </button>
                <div class="panel">
                  <p className="pt-2">
                    Kindly wait for a few minutes and refresh your email and
                    if you still do not have your ticket,please contact
                    customer care.
                  </p>
                </div>

                <button class="accordion">
                  Where can I find information on prices?{" "}
                </button>
                <div class="panel">
                  <p className="pt-2">
                    Pricing varies by location. You can view prices by
                    clicking on a showtime for the movie you'd like to see.
                  </p>
                </div>

                <button class="accordion">
                  Can I buy tickets for a group of people?
                </button>
                <div class="panel">
                  <p className="pt-2">
                    You can buy as many as 10 tickets per transaction. If
                    you're interested in group bookings,kindly contact
                    customer care.
                  </p>
                </div>

                <button class="accordion">
                  Can I buy a ticket for someone else?
                </button>
                <div class="panel">
                  <p className="pt-2">
                    Yes you can. You can input the person's name,email
                    address and phone number at the point of purchase.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <FooterTop />
          <Footer />
        </main>
      </div>
    );
  }
}

export default Help;