import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { register } from '../redux/actions/register';

import Register from '../components/Register';

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators({ register }, dispatch)
}
const mapStateToProps = (state, ownProps) => {
    let register = state.register;
    console.log('register state: ', register)
    return {
        registerData: register,
        user: register.user,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Register)