import React, { Component } from 'react';
import Footer from './includes/Footer';
import FooterTop from './includes/FooterTop';
import Nav from './includes/Nav';

class Terms extends Component {
  render() {
    return (
      <div>
        <Nav />
        <main style={{ marginTop: "10rem" }}>
          <div className="container">
            <div className="terms">
              <h1>TERMS AND CONDITIONS FOR SUBSCRIPTION</h1>
              <p>
                <h2>Please read</h2>
                <p>
                  Nairabox attempts to provide access to movies in participating cinemas nationally. However, participating cinemas, as well the time and locations for movies may vary depending on the specific service offering that you have selected at the time of enrollment, or such subsequent changes in the selection made by you, as well as movie inventory, usage, showtime availability, and Nairabox system-wide capacity and demand. YOU SHOULD CHECK OUR NAIRABOX APP FREQUENTLY FOR CHANGES IN OUR SERVICES AND ALL OFFERINGS INCLUDING UPDATES ON CINEMA PARTICIPATION. It is not until you purchase a ticket on the app, that the seat will be reserved and confirmed for the title and showtime selected. If the title and showtime you want to see is not available, Nairabox will not be held responsible as unforeseen circumstances can arise
                </p>
                <p>
                  Subject to the conditions contained in this terms of service, a valid Nairabox subscription entitles you to access any movie, at any showtime, in any location selected from Nairabox’s current movie inventory. The Service is available every week day at participating cinemas, subject to the cinema being open, inventory availability, and system capacity. THE SERVICE PROHIBITS REPEAT VIEWINGS OF THE SAME MOVIE
                </p>
                <p>
                  Nairabox makes no guarantee on the availability to any particular cinema, showtime, or title that is presented in our app. Nairabox ticket inventory may vary from specific cinema ticket inventory. Nairabox reserves the right to adjust its inventory to maintain fair access and usage to its full customer base. Nairabox

                  may utilize its proprietary data and algorithms to impose restrictions on individual users based on their location, day of movie, time of movie, title, and the individual user’s historical usage. This means that Nairabox has the right to limit the selection of movies and/or the times of available movies should your individual use adversely impact Nairabox’s system-wide capacity or the availability of the Service for other subscribers.
                </p>
                <p>
                  You agree to choose the movie title, cinema, and showtime up to no more than three (3) hours prior to the selected showtime, through the Nairabox App. You also agree to only redeem for a single ticket purchase a day. The cinema subscription access does not serve as a confirmation; it is only once you have purchased a ticket with your subscription

                  and received confirmation email, that a seat has been reserved. Nairabox is not responsible if you select the incorrect movie title, showing, or theater.

                </p>
                <p>
                  Nairabox reserves the right to remove access/close your account if there is any indication of fraud (duplicate account etc). You can appeal but you will need to provide a photo ID, and selfie with a recent newspaper showing date and BVN/NIN. You will also need to pay for the cost of verifying the ID.
                </p>
                <p>
                  You consent to Nairabox confirming that the ticket for the selected movie and showtime has in fact been used by the Subscriber by randomly, or otherwise for good cause, requiring the Subscriber to submit to ticket verification by submitting to Nairabox a picture of the ticket stub for the selected movie and showtime.
                </p>
                <p>
                  Nairabox may also employ other reasonable methods, such as requiring an email response from or attendance survey by the Subscriber, to confirm that the Subscriber in fact attended the selected movie and showtime. If these are not answered, the service may be suspended
                </p>
                <p>
                  You consent to Nairabox using Location Services to validate the location of the cinema and to ensure that only the Subscriber himself/herself is using the product. You further consent to nairabox using Location Services to confirm that the Subscriber is actually attending the selected movie and showtime by verifying the Subscriber’s location at the cinema at both the start of the movie and at any time during the selected movie.
                </p>
                <p>
                  Nairabox offers monthly subscriptions payable either month-to-month or quarterly. Nairabox will bill upon subscription to the service and then each renewal period to the terms of your subscription, unless either party seeks to terminate the agreement. You must cancel your membership before it renews in order to avoid billing the next period's membership fees to the payment method you provided to us. THERE IS NO REIMBURSEMENT FOR EARLY TERMINATION OF A MEMBERSHIP. If you do not contact us prior to the billing date, your card will be charged and termination will occur at the end of the next billing cycle.
                </p>
                <p>
                  NAIRABOX SUBSCRIPTIONS ARE INTENDED FOR THE USE OF THE SUBSCRIBER ONLY AND ARE NOT TO BE SHARED WITH ANYONE ELSE. NAIRABOX

                  SUBSCRIBERS MAY ONLY USE THE NAIRABOX APPLICATION ON ONE ACTIVE DEVICE (PHONE) AT A TIME. NAIRABOX SUBSCRIBERS ARE ONLY PERMITTED TO SWITCH ACTIVE DEVICES ONCE PER 6 (SIX)MONTHS.
                </p>
                <p>
                  Nairabox reserves the right to revise these terms and conditions without prior notice
                </p>
                <p>
                  Meant strictly for private use only and is not for sale under any circumstances.
                </p>
                <p>
                  Not applicable for any other promotions, items from the concession, group booking, hall booking and not exchangeable for cash.</p>
                <p>
                  Not valid for IMAX, 3d, MX4D and any other specialty halls
                </p>
                <p>
                  Valid for standard seats at standard halls only.
                </p>
                <p>
                  <div>JORG Technologies Limited </div>
                  <div>5A, Ayinde Akinmade Street</div>
                  <div>Off Admiralty Way, </div>
                  <div>Lekki Peninsula Scheme 1</div>
                  <div>Lagos, Nigeria</div>
                  <div>07043638067</div>
                  <div>support@nairabox.com</div>
                </p>
              </p>
            </div>
          </div>
          <FooterTop />
          <Footer />
        </main>
      </div>
    );
  }
}

export default Terms;