import {
    GET_SOLDOUT_PENDING,
    GET_SOLDOUT_SUCCESS, GET_SOLDOUT_FAILED,
} from '../actions/types';
let initialState = {
    fetching: false,
    fetched: false,
    soldout: null,
    error: null,
};
export default function soldoutReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SOLDOUT_PENDING:
            return { ...state, fetching: true, fetched: false, error: null };
        case GET_SOLDOUT_SUCCESS:
            let soldout = action.payload;
            return { ...state, soldout, fetching: false, fetched: true, error: null };
        case GET_SOLDOUT_FAILED:
            return { ...state, fetching: false, fetched: false, error: action.payload };

        default: return { ...state };
    }
}