import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import "./env.js";
// import App from './App';
import Router from "./components/Router";

import configureStore from "./redux/store";

import * as serviceWorker from "./serviceWorker";

// import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/main.css";
import "slick-carousel/slick/slick.css";
import ReactGA from "react-ga";
//import "slick-carousel/slick/slick-theme.css";

const store = configureStore();
function initializeReactGA() {
  ReactGA.initialize("UA-167686579-3");
  ReactGA.pageview("/movies");
}

initializeReactGA();

ReactDOM.render(
  <Provider store={store}>
    <Router />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
