import { combineReducers } from 'redux';
import { signupReducer } from './signupReducer';
import movies from './moviesReducer';
import cinemas from './cinemasReducer';
import moviesFilter from './moviesFilterReducer';
import moviesFilterByCinema from './moviesFilterByCinemaReducer';
import movie from './movieReducer';
import { loading } from './uiReducer';
import showtime from './showtimeReducer';
import soldout from './soldoutReducer';

import login from './loginReducer';
import register from './registerReducer';
import user from './userReducer';

export default combineReducers({
    signup: signupReducer, movies, movie, loading, showtime, soldout,
    moviesFilter, moviesFilterByCinema, cinemas,
    login, register, user
});