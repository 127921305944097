import React, { Component } from 'react';
import Footer from './includes/Footer';
import FooterTop from './includes/FooterTop';
import Nav from './includes/Nav';

class Terms extends Component {
  render() {
    return (
      <div>
        <Nav />
        <main style={{ marginTop: "10rem" }}>
          <div className="container">
            <div className="terms">
              <h1>Nairabox Terms of Use</h1>
              <p>
                <h2>NAIRABOX TERMS OF USE</h2>

                <h3>1. CONTRACTUAL RELATIONSHIP. </h3>
                <p>
                  These Terms of Use ("Terms") govern your access or use of
                  the applications, websites, content, products, and
                  services (the "Services," as more fully defined below in
                  Section 2) made available in Nigeria by JORG Technologies
                  Limited and its parents, subsidiaries, representatives,
                  affiliates, officers and directors (collectively, "JORG"
                  or “JORG TECHNOLOGIES”). PLEASE READ THESE TERMS
                  CAREFULLY, AS THEY CONSTITUTE A LEGAL AGREEMENT BETWEEN
                  YOU AND JORG. In these Terms, the words "including" and
                  "include" mean "including, but not limited to."
                </p>
                <p>
                  By accessing or using the Services, you confirm your
                  agreement to be bound by these Terms. If you do not agree
                  to these Terms, you may not access or use the Services.
                  These Terms expressly supersede prior agreements or
                  arrangements with you. JORG may immediately terminate
                  these Terms or any Services with respect to you, or
                  generally cease offering or deny access to the Services or
                  any portion thereof, at any time for any reason.
                </p>
                <h3>2. SERVICES.</h3>
                <p>
                  The Services comprise mobile applications and related
                  services (each, an "Application"), which enable users to
                  initiate, perform, order and complete electronic commerce
                  transactions, schedule and/or pay for completed
                  transactions, purchase merchandise or goods, interact with
                  other connected users or third parties fulfilment partners
                  (“THIRD-PARTY FULFILMENT PARTNERS”). In certain instances,
                  the Services may also include an option to opt-in to
                  loyalty schemes, receive promotions and bonuses. Unless
                  otherwise agreed by JORG in a separate written agreement
                  with you, the Services are made available solely for your
                  personal, non-commercial use.
                </p>
                <h3>3. LICENSE.</h3>
                <p>
                  Subject to your compliance with these Terms, JORG grants
                  you a limited, non-exclusive, non-sublicensable,
                  revocable, non-transferable license to: (i) access and use
                  the Applications on your personal device solely in
                  connection with your use of the Services; and (ii) access
                  and use any content, information and related materials
                  that may be made available through the Services, in each
                  case solely for your personal, non-commercial use. Any
                  rights not expressly granted herein are reserved by JORG
                  and JORG’s licensors.
                </p>
                <h3>4. RESTRICTIONS.</h3>
                <p>
                  You may not: (i) remove any copyright, trademark or other
                  proprietary notices from any portion of the Services; (ii)
                  reproduce, modify, prepare derivative works based upon,
                  distribute, license, lease, sell, resell, transfer,
                  publicly display, publicly perform, transmit, stream,
                  broadcast or otherwise exploit the Services except as
                  expressly permitted by JORG; (iii) decompile, reverse
                  engineer or disassemble the Services except as may be
                  permitted by applicable law; (iv) link to, mirror or frame
                  any portion of the Services; (v) cause or launch any
                  programs or scripts for the purpose of scraping, indexing,
                  surveying, or otherwise data mining any portion of the
                  Services or unduly burdening or hindering the operation
                  and/or functionality of any aspect of the Services; or
                  (vi) attempt to gain unauthorized access to or impair any
                  aspect of the Services or its related systems or networks.
                </p>
                <h3>5. PROVISION OF THE SERVICES.</h3>
                <p>
                  You acknowledge that portions of the Services may be made
                  available under JORG’s various brands or request options
                  associated with consultation and opinions, including
                  Nairabox. You also acknowledge that the Services may be
                  made available under such brands or request options by or
                  in connection with: (i) certain of JORG’s subsidiaries and
                  affiliates; or (ii) independent Third-Party Fulfilment
                  Partners.{" "}
                </p>
                <h3>6. OWNERSHIP.</h3>
                <p>
                  The Services and all rights therein are and shall remain
                  JORG’s property or the property of JORG’s licensors.
                  Neither these Terms nor your use of the Services convey or
                  grant to you any rights: (i) in or related to the Services
                  except for the limited license granted above; or (ii) to
                  use or reference in any manner JORG’s company names,
                  logos, product and service names, trademarks or services
                  marks or those of JORG’s licensors.
                </p>
                <h3>7. USER ACCOUNT.</h3>
                <p>
                  In order to use the Services, you are required to create
                  and maintain an active personal account (“Account”) by
                  entering your name, email address, password and certain
                  other information collected by JORG (collectively “Account
                  Information”). To create an Account, you must be of legal
                  age to form a binding contract. If you are not of legal
                  age to form a binding contract, you may not register to
                  use the Services. You agree that the Account Information
                  that you provide to us at all times, including during
                  registration and in any information, you upload for the
                  Services will be true, accurate, current, and complete.
                  You may not transfer or share your Account password with
                  anyone. You are responsible for maintaining the
                  confidentiality of your Account password and for all
                  activities that occur under your Account. You may not use
                  anyone else’s account at any time. Unless otherwise
                  permitted, you may only possess one Account.
                </p>
                <h3>8. USER REQUIREMENTS AND ELIGIBILITY.</h3>
                <p>
                  The Service is not available for use by persons under the
                  age of 16. You may not authorize third parties to use your
                  Account, and you may not allow persons under the age of 16
                  to initiate transactions using the Services or interact
                  with Third-Party Fulfilment Partners unless they are
                  accompanied by you. You may not assign or otherwise
                  transfer your Account to any other person or entity. You
                  agree to comply with all applicable laws when accessing or
                  using the Services, and you may only access or use the
                  Services for lawful purposes. You may not, in your access
                  or use of the Services, cause nuisance, annoyance or
                  inconvenience to any other party. In certain instances,
                  you may be asked to provide proof of identity to access or
                  use the Services, and you agree that you may be denied
                  access to or use of the Services if you refuse to provide
                  proof of identity.
                </p>
                <h3>9. USER-PROVIDED CONTENT.</h3>
                <p>
                  JORG may, in JORG’s sole discretion, permit you from time
                  to time to submit, upload, publish or otherwise make
                  available to JORG through the Services textual, audio,
                  and/or visual content and information, including
                  commentary and feedback related to the Services,
                  initiation of support requests, and submission of entries
                  for events and promotions ("User Content"). Any User
                  Content provided by you remains your property. However, by
                  providing User Content to JORG, you grant JORG a
                  worldwide, perpetual, irrevocable, transferable,
                  royalty-free license, with the right to sublicense, to
                  use, copy, modify, create derivative works of, distribute,
                  publicly display, publicly perform, and otherwise exploit
                  in any manner such User Content in all formats and
                  distribution channels now known or hereafter devised
                  (including in connection with the Services and JORG’s
                  business and on third-party sites and services), without
                  further notice to or consent from you, and without the
                  requirement of payment to you or any other person or
                  entity.
                </p>
                <p>
                  You represent and warrant that: (i) you either are the
                  sole and exclusive owner of all User Content or you have
                  all rights, licenses, consents and releases necessary to
                  grant JORG the license to the User Content as set forth
                  above; and (ii) neither the User Content, nor your
                  submission, uploading, publishing or otherwise making
                  available of such User Content, nor JORG’s use of the User
                  Content as permitted herein will infringe, misappropriate
                  or violate a third party's intellectual property or
                  proprietary rights, or rights of publicity or privacy, or
                  result in the violation of any applicable law or
                  regulation.
                </p>
                <p>
                  You agree to not provide User Content that is defamatory,
                  libelous, hateful, violent, obscene, pornographic,
                  unlawful, or otherwise offensive, as determined by JORG in
                  its sole discretion, whether or not such material may be
                  protected by law. JORG may, but shall not be obligated to,
                  review, monitor, or remove User Content, at JORG’s sole
                  discretion and at any time and for any reason, without
                  notice to you.
                </p>
                <h3>10. PRIVACY</h3>
                <p>
                  JORG is required to comply with applicable privacy and
                  security laws and maintain safeguards to protect the
                  security of your personal information. Additionally, the
                  information you provide to Third-Parties during the use of
                  the Services is legally confidential, except for certain
                  legal exceptions described in any Privacy Policy. We
                  devote considerable effort toward ensuring that your
                  personal information is secure. Information regarding our
                  use of personal information is provided in our Privacy
                  Policy. As part of providing you the Services, we may need
                  to provide you with certain communications, such as
                  notifications, confirmations and administrative messages.
                  These communications are considered part of the Services
                  and your Account. While secure electronic messaging is
                  always preferred to insecure email, under certain
                  circumstances, insecure email communication containing
                  personal information may take place between you and JORG.
                  JORG cannot ensure the security or confidentiality of
                  messages sent by email. Your personal information are
                  stored on secure, encrypted servers maintained either
                  JORG, its affiliates or partners.
                </p>
                <h3>11. NETWORK ACCESS AND DEVICES.</h3>
                <p>
                  You are responsible for obtaining the data network access
                  necessary to use the Services. Your mobile network's data
                  and messaging rates and fees may apply if you access or
                  use the Services from your device. You are responsible for
                  acquiring and updating compatible hardware or devices
                  necessary to access and use the Services and Applications
                  and any updates thereto. JORG does not guarantee that the
                  Services, or any portion thereof, will function on any
                  particular hardware or devices. In addition, the Services
                  may be subject to malfunctions and delays inherent in the
                  use of the Internet and electronic communications.
                </p>
                <h3>12. PAYMENTS AND CHARGES.</h3>
                <p>
                  You understand that use of the Services may result in
                  charges to you for the services you receive ("Charges").
                  JORG will receive and/or enable your payment of the
                  applicable Charges for services obtained through your use
                  of the Services. Charges will be inclusive of applicable
                  taxes where required by law. Charges may include other
                  applicable fees and/or surcharges including a booking fee.
                </p>
                <p>
                  All Charges and payments will be enabled by JORG using the
                  preferred payment method designated in your Account, after
                  which you will receive a receipt by email. If your primary
                  Account payment method is determined to be expired,
                  invalid or otherwise not able to be charged, you agree
                  that JORG may use a secondary payment method in your
                  Acccount, if available. Charges paid by you are final and
                  non-refundable, unless otherwise determined by JORG.
                </p>
                <p>
                  As between you and JORG, JORG reserves the right to
                  establish, remove and/or revise Charges for any or all
                  services or goods obtained through the use of the Services
                  at any time in JORG’s sole discretion. Further, you
                  acknowledge and agree that Charges applicable in certain
                  geographical areas may increase substantially during times
                  of high demand. JORG will use reasonable efforts to inform
                  you of Charges that may apply, provided that you will be
                  responsible for Charges incurred under your Account
                  regardless of your awareness of such Charges or the
                  amounts thereof. You may elect to cancel your request for
                  Services at any time prior to the commencement of such
                  Services, in which case you may be charged a cancellation
                  fee.{" "}
                </p>
                <h3>13. DISCLAIMER.</h3>
                <p>
                  THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE." JORG
                  DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS,
                  IMPLIED, OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE
                  TERMS, INCLUDING THE IMPLIED WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                  NON-INFRINGEMENT. IN ADDITION, JORG MAKES NO
                  REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE
                  RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, OR
                  AVAILABILITY OF THE SERVICES OR ANY SERVICES OR GOODS
                  REQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE
                  SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. JORG DOES
                  NOT GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR ABILITY
                  OF THIRD-PARTY FULFILMENT PARTNERS. YOU AGREE THAT THE
                  ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND
                  ANY SERVICE OR GOOD REQUESTED IN CONNECTION THEREWITH,
                  REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED
                  UNDER APPLICABLE LAW.
                </p>
                <h3>14. LIMITATION OF LIABILITY.</h3>
                <p>
                  JORG SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL,
                  SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES,
                  INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY, OR
                  PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR
                  OTHERWISE RESULTING FROM ANY USE OF THE SERVICES,
                  REGARDLESS OF THE NEGLIGENCE (EITHER ACTIVE, AFFIRMATIVE,
                  SOLE, OR CONCURRENT) OF JORG, EVEN IF JORG HAS BEEN
                  ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </p>
                <p>
                  JORG SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR
                  LOSSES ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE
                  SERVICES OR YOUR INABILITY TO ACCESS OR USE THE SERVICES;
                  OR (ii) ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND
                  ANY THIRD-PARTY, EVEN IF JORG HAS BEEN ADVISED OF THE
                  POSSIBILITY OF SUCH DAMAGES. JORG SHALL NOT BE LIABLE FOR
                  DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES
                  BEYOND JORG’S REASONABLE CONTROL. YOU ACKNOWLEDGE THAT
                  THIRD-PARTY FULFILMENT PARTNERS MAY USE THE SERIVCES AND
                  MAY NOT BE AFFILIATED OR OTHERWISE PERMITTED BY JORG.
                </p>
                <p>
                  THE SERVICES MAY BE USED BY YOU TO REQUEST AND BOOK ANY
                  EVENT, ACCESS AND PAY FOR A SERVICE, AND OTHERWISE CONNECT
                  WITH THIRD-PARTY FULFILMENT PARTNERS, BUT YOU AGREE THAT
                  JORG HAS NO RESPONSIBILITY OR LIABILITY TO YOU RELATED TO
                  ANY THIRD PARTY SERVICE PROVIDED TO YOU BY THIRD-PARTY
                  FULFILMENT PARTNERS OTHER THAN AS EXPRESSLY SET FORTH IN
                  THESE TERMS.
                </p>
                <p>
                  THE LIMITATIONS AND DISCLAIMER IN THIS SECTION DO NOT
                  PURPORT TO LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A
                  CONSUMER THAT CANNOT BE EXCLUDED UNDER APPLICABLE LAW.
                  THIS PROVISION SHALL HAVE NO EFFECT ON JORG’S CHOICE OF
                  LAW PROVISION SET FORTH BELOW.
                </p>
                <h3>15. INDEMNITY.</h3>
                <p>
                  BY USING THE SERVICES, YOU AGREE TO INDEMNIFY, DEFEND, AND
                  HOLD HARMLESS JORG, AND ANY AFFILIATED JORG ENTITY, ITS
                  OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS, AGENTS, AND
                  REPRESENTATIVES (COLLECTIVELY “JORG PARTIES”) FROM AND
                  AGAINST ALL CLAIMS, ACTIONS, DEMANDS, LIABILITIES,
                  JUDGMENTS, SETTLEMENTS, COSTS, LOSSES, DAMAGES, TAX
                  ASSESSMENTS, PENALTIES, INTEREST AND EXPENSES (INCLUDING,
                  WITHOUT LIMITATION, REASONABLE ATTORNEYS’ FEES) ARISING
                  OUT OF ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL,
                  SPECIAL, EXEMPLARY, PUNITIVE OR ANY OTHER CLAIM YOU MAY
                  INCUR IN CONNECTION WITH YOUR USE OF THIS SITE, INCLUDING,
                  WITHOUT LIMITATION, ANY CLAIMS FOR DEFAMATION, VIOLATION
                  OF PUBLICITY OR PRIVACY, COPYRIGHT OR TRADEMARK
                  INFRINGEMENT RESULTING FROM YOUR SUBMISSIONS OF ANY
                  CONTENT, TAGGED CONTENT OR OTHER MATERIALS, ANY ECONOMIC
                  HARM, LOST PROFITS, DAMAGES TO BUSINESS, DATA OR COMPUTER
                  SYSTEMS, OR ANY DAMAGES RESULTING FROM RELIANCE ON ANY
                  CONTENT OR RESULTING FROM ANY INTERRUPTIONS, WORK
                  STOPPAGES, COMPUTER FAILURES, DELETION OF FILES, ERRORS,
                  OMISSIONS, INACCURACIES, DEFECTS, VIRUSES, DELAYS OR
                  MISTAKES OF ANY KIND, EVEN IF YOU HAVE PREVIOUSLY ADVISED
                  JORG OF THE POSSIBILITY OF SUCH CLAIM.
                </p>
                <h3>16. DISCLOSURES.</h3>
                <p>
                  All Third-Party Fulfilment Partners associated with the
                  Services hold licenses or other business registrations,
                  certificates and clearances issued by the respective
                  regulatory authority. You can report a complaint relating
                  to services provided by a Third-Party Fulfilment Partner
                  by either directly contacting the regulatory authority of
                  such entity or directing such complaints to JORG. In a
                  business relationship, fraud is never appropriate and is
                  unlawful, and should be reported to law enforcement
                  agents.
                </p>
                <h3>17. JURISDICTION.</h3>
                <p>
                  Information provided by JORG in connection with the
                  Services is not targeted to users in any particular
                  locality nor is it intended to constitute the doing of
                  business in any jurisdiction.{" "}
                </p>
                <p>
                  The Services provided by JORG do not constitute any
                  contact with any jurisdiction outside the Federal Republic
                  of Nigeria. Use of the Services is prohibited in any
                  jurisdiction having laws that would void this Agreement in
                  whole or essential part or which makes accessing the
                  Services illegal. Users in such jurisdictions visit and
                  use this Services entirely at their own risk. Note: the
                  essential parts of this Agreement include, without
                  limitation, the exclusive venue and exclusive remedy
                  provisions and the warranty disclaimers.
                </p>
                <p>
                  This Agreement is entered into and performed in the
                  Federal Republic of Nigeria. It is governed by and shall
                  be construed under the laws of the Federal Republic of
                  Nigeria, exclusive of any choice of law or conflict of law
                  provisions.
                </p>
                <h3>18. LIMITATION ON TIME TO INITIATE A DISPUTE.</h3>
                <p>
                  Unless otherwise required by law, an action or proceeding
                  by you relating to any Dispute must commence within one
                  year after the cause of action accrues.
                </p>
                <h3>19. ARBITRATION ONLY.</h3>
                <p>
                  Any dispute arising under or relating in any way to the
                  Terms will be resolved exclusively by final and binding
                  arbitration in Lagos State, Nigeria under the rules of the
                  Lagos Multidoor Courthouse, except that either party may
                  bring a claim related to intellectual property rights, or
                  seek temporary and preliminary specific performance and
                  injunctive relief, in any court of competent jurisdiction.
                  The parties agree to the personal and subject matter
                  jurisdiction and venue of the courts located in Lagos
                  Island, Lagos State for any action related to these Terms
                  of Use. You understand that by checking the “agree” box
                  for these Terms of Use and/or any other forms presented to
                  you, you are agreeing to these Terms of Use and that such
                  action constitutes a legal signature.
                </p>
                <h3>20. OTHER PROVISIONS.</h3>
                <p>
                  Except as expressly provided in these Terms, these terms
                  are a complete statement of the agreement between you and
                  JORG, and they describe the entire liability of JORG and
                  its partnerss and your exclusive remedy with respect to
                  your access and use of the Services. In the event of a
                  conflict between these Terms and any other JORG agreement
                  or policy, these Terms shall prevail on the subject matter
                  of these Terms. If any provision of these Terms is invalid
                  or unenforceable under applicable law, then it shall be
                  changed and interpreted to accomplish the objectives of
                  such provision to the greatest extent possible under
                  applicable law, and the remaining provisions will continue
                  in full force and effect. Headings are included for
                  convenience only, and shall not be considered in
                  interpreting these Terms. These Terms do not limit any
                  rights that JORG may have under trade secret, copyright,
                  patent, or other laws. JORG’s failure to assert any right
                  or provision under these Terms shall not constitute a
                  waiver of such right or provision. No waiver of any term
                  of this Agreement shall be deemed a further or continuing
                  waiver of such term or any other term.
                </p>
                <h3>21. ASSIGNMENT.</h3>
                <p>
                  You may not assign any rights or obligations under this
                  Agreement without JORG’s prior written consent. JORG may
                  assign all or part of this Agreement.
                </p>
                <h3>22. SURVIVAL.</h3>
                <p>
                  All sections of this Agreement which, by their nature are
                  designed to survive expiration or termination of this
                  Agreement, including but not limited to indemnity and
                  limitation of liability clauses, shall survive.
                </p>
                <h3>23. WAIVER.</h3>
                <p>
                  No waiver of any of these terms shall be deemed a further
                  or continuing waiver of such term or condition or any
                  other term or condition.
                </p>
                <h3>24. NOTICES.</h3>
                <p>
                  You agree that we may provide any and all notices to you
                  by e-mail, telephone, fax, as well as by any other method.
                </p>
                <h3>25. SEVERABILITY.</h3>
                <p>
                  If any of the provisions of this Agreement are held
                  unenforceable by a court or other tribunal of competent
                  jurisdiction, then those provisions shall be limited or
                  eliminated to the minimum extent necessary to allow the
                  remainder of this Agreement to retain its full force and
                  effect.
                </p>
                <h3>26. ENTIRE AGREEMENT; AMENDMENT.</h3>
                <p>
                  This Agreement constitutes the entire agreement between
                  you and JORG TECHNOLOGIES applicable to its subject
                  matter. We may at our sole discretion change, add, or
                  delete portions of these Terms at any time on a
                  going-forward basis. Continued use of the Services
                  following notice of any such changes will indicate your
                  acknowledgement of such changes and agreement to be bound
                  by the revised Terms, inclusive of such changes.{" "}
                </p>
                <h3>27. CONTACT INFORMATION.</h3>
                <p>
                  Please contact us with any questions or concerns regarding
                  this Agreement at:
                </p>
                <p>
                  <div>JORG Technologies Limited </div>
                  <div>5A, Ayinde Akinmade Street</div>
                  <div>Off Admiralty Way, </div>
                  <div>Lekki Peninsula Scheme 1</div>
                  <div>Lagos, Nigeria</div>
                  <div>09080000381</div>
                  <div>support@nairabox.com</div>
                </p>
              </p>
            </div>
          </div>
          <FooterTop />
          <Footer />
        </main>
      </div>
    );
  }
}

export default Terms;