import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { login } from '../redux/actions/login';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: '',
      password: '',
    }
  }
  componentDidUpdate(prevProps, prevState) {
    let { loginData } = this.props;
    if (loginData && loginData.user) {
      let { user } = loginData;
      let { token } = user;
      let prevToken = prevProps.loginData.user ? prevProps.loginData.user.token : null;

      if (token && (token !== prevToken)) {
        this.setState({ token: token })
        this.setState({ user: user });
        localStorage.setItem('moviesubscription:token', token);
        let serializedUser = JSON.stringify(user);
        localStorage.setItem('moviesubscription:user', serializedUser);

        let to;
        if (user.movies_subscription.status === 'inactive') to = '/moviesubscription'
        else to = '/'

        let { from } = this.props.location.state || { from: { pathname: to } };
        this.props.history.push(from);
      }
    }
  }
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  }

  handleSubmit = async () => {
    await this.props.login(this.state);
  }

  render() {
    return (
      <div className="login">
        <div className="row my-4">
          <a href="/"><img src="../assets/images/nairabox-logo.png" alt="Nairabox Logo" /></a>
        </div>

        <div className="row my-4 p-4 authpages">
          <div className="col-md-6 login__form">
            <div className="welcome-text">Welcome back!</div>
            <div className="signup-text">Not yet registered?
             <Link to={{ pathname: '/register' }}> Register </Link>
            </div>
            <div className="row">
              <div className="col-md-12 login__form-input">
                <input type="text" name="uid"
                  value={this.state.uid}
                  onChange={this.handleInputChange}
                  placeholder="Enter your email address" />
              </div>
              <div className="col-md-12 login__form-input">
                <input type="password" name="password"
                  value={this.state.password}
                  onChange={this.handleInputChange}
                  placeholder="Password" />
              </div>
              <div className="col-md-12 login__form-input">
                <button onClick={this.handleSubmit}
                  className="ticket-button block-btn">Login</button>
              </div>
            </div>
            <div className="forgot_password"><a href="#/somelink">Forgot Password?</a></div>
          </div>

          <div className="col-md-6 login__side">
            <div className="login__side-content">
              <img src="../assets/images/undraw_movie_night_93wl.png" alt="Login" />
              <div className="login__lead">All your movies for a small price</div>
              <div className="">This subscription allows you to access any movie of your choice</div>
            </div>
          </div>
        </div>
        {/* <div className="row my-4"><img src="../assets/images/powered_by.png" alt="Powered by VISA"/></div> */}
      </div>
    )
  }
}

export default Login;