import axios from 'axios';
import globals from '../../globals';
import { REGISTER_PENDING, REGISTER_SUCCESS, REGISTER_FAILED, LOADING_START, LOADING_END } from '../actions/types';

const authPath = '/user';
const authUrl = `${globals.api}${authPath}`;
const authUrl2 = `https://movie-visa.herokuapp.com/api/v1/user`

export function register(userObj) {
    return async (dispatch) => {
        dispatch({ type: REGISTER_PENDING });
        dispatch({ type: LOADING_START, loadingText: ' ... signing you up ... ' })
        let res;
        try {
            res = await axios.post(authUrl2, userObj);
            dispatch({ type: LOADING_END })
            dispatch({ type: REGISTER_SUCCESS, payload: res.data });
        } catch (error) {
            //console.log('Problem registering. Error: ', error);
            dispatch({ type: LOADING_END })
            dispatch({ type: REGISTER_FAILED, payload: error });

        }
    }
}