import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { slugify } from '../helpers';

export default class OtherMovieItem extends Component {
  reload() {
    window.location.reload()
  }
  render() {
    var img = ""
    const { featuredImage, title, description, id } = this.props.movie;
    return (
      // <div className="col-md-3 text-center movie__others" key={id}>
      //   {/* <a href="https://nokia.ly/2Vfr9Vb"><img src="https://res.cloudinary.com/dwa8k0pds/image/upload/v1558524730/nokia_740x320.svg"></img></a> */}
      //   <Link
      //     to={{
      //       // pathname: `/movies/${slugify(title)}`,
      //       pathname: `/movies/${id}`,
      //       state: { id, title, description },
      //       key: { id }
      //     }}
      //   >
      //     <img src={featuredImage} onClick={this.reload} key={id} alt={title.toLowerCase()} />
      //   </Link>
      // </div>
      <div></div>
    )
  }
}
