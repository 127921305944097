import { createStore, applyMiddleware, compose } from 'redux';
//import logger from 'redux-logger';

import thunk from 'redux-thunk';
import reducers from './reducers';

const middlewares = applyMiddleware( thunk);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(/*preloadedState*/) {
    const store = createStore(reducers, /*preloadedState,*/ composeEnhancers(middlewares));
    return store;
}
