import React, { Component } from 'react'
import Payment from '../subscription/Payment'

class Subscribe extends Component {
  render() {
    return (
      <div className="login">
        <div className="row my-4">
          <a href="/"><img src="../assets/images/nairabox-logo.png" alt="Nairabox Logo" /></a>
        </div>

        <div className="row my-4 p-4 authpages">
          <div className="col-md-6 login__form">
            <div className="welcome-text">Pay your subscription</div>
            <div className="row">
              <div className="col-md-12 login__form-input">
                <div className="price-card">
                  <div className="price-card__plan">
                    <p className="green-color">Regular</p>
                    <p className="sub-price">N1, 000</p>
                  </div>
                  <div className="price-card__icon">
                    <img src="../assets/images/icons/info-icon.png" alt="More info" />
                  </div>
                </div>
              </div>
              <div className="col-md-12 price-card__total">
                <p>Total:</p>
                <p>N1000</p>
              </div>

              <div className="col-md-12 login__form-input">
                <Payment className="ticket-button block-btn"
                  history={this.props.history}
                  user={this.props.user} amount={1000} />
                {/* <button className="ticket-button block-btn">Pay Now</button> */}
              </div>

            </div>
          </div>

          <div className="col-md-6 login__side">
            <div className="login__side-content">
              <img src="../assets/images/undraw_movie_night_93wl.png" alt="Login" />
              <div className="login__lead">All your movies for a small price</div>
              <div className="">This subscription allows you to access any movie of your choice</div>
            </div>
          </div>
        </div>

        {/* <div className="row my-4"><img src="../assets/images/powered_by.png" alt="Powered by VISA"/></div> */}
      </div>
    )
  }
}

export default Subscribe;