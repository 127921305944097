import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class SubscriptionSuccessful extends Component {
  render() {
    return (
      <div className="login">
        <div className="row my-4">
          <a href="/"><img src="../assets/images/nairabox-logo.png" alt="Nairabox Logo" /></a>
        </div>

        <div className="row my-4 p-4 authpages">
          <div className="col-md-6 login__form">
            <div className="successful-text green-color">SUBSCRIPTION SUCCESSFUL</div>
            <div className="signup-text">
              Thank you for subscribing, you have now unlocked your
              monthly access to 3 cinema tickets, which can be redeemed
              in all cinemas in major locations nationwide.
            </div>
            <div className="row y-to-home-div">
              <div className="col-md-12 login__form-input">
                <Link className="ticket-button" to={'/'}> Return to Home </Link>
                {/* <button className="ticket-button">Return to Home</button> */}
              </div>
            </div>
          </div>

          <div className="col-md-6 login__side">
            <div className="login__side-content">
              <img src="../assets/images/undraw_movie_night_93wl.png" alt="Login" />
              <div className="login__lead">All your movies for a small price</div>
              <div className="">This subscription allows you to access any movie of your choice</div>
            </div>
          </div>
        </div>

        {/* <div className="row my-4"><img src="../assets/images/powered_by.png" alt="Powered by VISA" /></div> */}
      </div>
    )
  }
}

export default SubscriptionSuccessful;