import React, { Component } from "react";
import { withRouter } from 'react-router-dom'
class Timer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mins: " ",
            secs: "00",
            minsDisplay: " ",
            secsDisplay: "00"
        };
    }
    tick = () => {

        let mins = Math.floor(this.secsRemaining / 60);
        let secs = this.secsRemaining - (mins * 60);

        this.setState({ mins, secs })
        this.setState({ minsDisplay: mins, secsDisplay: secs })

        if (secs < 10) {
            this.setState({ secsDisplay: "0" + secs })
        }

        if (mins < 10) {
            this.setState({ minsDisplay: "0" + mins })
        }
        if (mins === 0 & secs === 0) {
            clearInterval(this.interval);
            this.props.history.push('/')
        }
        this.secsRemaining--
    };

    start = () => {
        this.secsRemaining = this.props.mins ? (this.props.mins * 60) : (10 * 60);
        this.interval = setInterval(this.tick, 1000);
    };

    stop = () => {
        clearInterval(this.interval);
    };

    componentDidMount() {
        this.start();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        let { minsDisplay, secsDisplay } = this.state;
        let display = `${minsDisplay}min : ${secsDisplay}sec`;
        return (
            <>
                <span> {display} </span>
            </>
        );
    }
}

export default withRouter(Timer);
