import { LOGIN_PENDING, LOGIN_SUCCESS, LOGIN_FAILED } from '../actions/types';
//GET_MOVIES_FILTER_PENDING, GET_MOVIES_FILTER_SUCCESS, GET_MOVIES_FILTER_FAILED,
const INITIAL_STATE = {
    fetching: false, fetched: false,
    isLoggedIn: false, user: null, error: null,
};

const login = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN_PENDING: {
            return { ...state, fetching: true, fetched: false };
        }
        case LOGIN_SUCCESS: {
            return {
                ...state, fetching: false, fetched: true, isLoggedIn: true,
                user: action.payload.user, error: null,
            };
        }
        case LOGIN_FAILED: {
            return { ...state, fetching: false, fetched: false, isLoggedIn: false, error: action.payload };
        }
        default: return { ...state }
    }
};
export default login;