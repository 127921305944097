import React, { Component } from 'react';
import Footer from '../../containers/includes/Footer';
import FooterTop from '../../containers/includes/FooterTop';
import AllMoviesList from './AllMoviesList';
import Nav from '../../containers/includes/Nav';

class Home extends Component {
  render() {
    return (
      <div>
        <Nav />
        <main style={{marginTop: "10rem"}}>
          <AllMoviesList />

          <FooterTop />
          <Footer />
        </main>

      </div>
    )
  }
}

export default Home;