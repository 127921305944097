import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//import { user } from '../../redux/actions/user';
import { user } from "../redux/actions/user";

//import News from '../containers/News';
import Footer from "../containers/includes/Footer";
import FooterTop from "../containers/includes/FooterTop";
import MovieVisa from "../containers/MovieVisa";
import MovieList from "../smarts/MovieList";
import FeaturedMovieList from "../smarts/FeaturedMovieList";
// import FeaturedMovie from '../components/FeaturedMovies';
import Header from "../containers/Header";
//import Nav from '../containers/includes/Nav';
import Nav from "../smarts/MoviesDataProvider";
import { Link } from "react-dom";
import { getUserFromLocal } from "../subscription/localUser";

import ScrollToMainContent from "../helperComponents/ScrollToMainContent";


class Home extends Component {
  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  closeModal = () => {
    if (window && window.$) {
      var $ = window.$;
      $('#staticBackdrop').modal('toggle');
      // window.localStorage && window.localStorage.setItem('showSubscribeModal', 'false');
    };
  }

  addToNotificationList = (e) => {
    e.preventDefault();
    fetch('https://movies-apis.nairabox.net/v2/tickets?&as=subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: e.target.email.value
      })
    }).then(() => {
      this.closeModal()
    }).catch(() => {
      console.log("SORRY AN ERROR OCCURED")
    })
  }

  componentDidMount() {
    console.log(process.env)
    let userLocal = getUserFromLocal();
    let { user } = this.props;
    if (userLocal && userLocal.token) {
      let { token } = userLocal;
      user(token);
    }
    window.scrollTo(0, 0);
    if (window && window.$) {
      // if (window.localStorage && window.localStorage.getItem('showSubscribeModal')  !== 'false'){
        var $ = window.$;
        $('#staticBackdrop').modal()
      // }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    let { userFresh } = this.props;
    if (userFresh /*&& loginData.user */) {
    }
  }

  render() {
    return (
      <div>
        <Nav location={this.props.location.pathname} />
        <main>
          <Header />

          <ScrollToMainContent />
          <FeaturedMovieList />
          <MovieList />
          {/* <MovieVisa /> */}
          {/* <News /> */}

          <div style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
            <a href="/movies" className="btn ticket-button allmovies">
              Show All
            </a>
          </div>
          <FooterTop />
          <Footer />
        </main>
        {/* <div class="foot" data-step="1">
          <a href="https://nokia.ly/2Vfr9Vb"><img className="img-responsive" src="./assets/images/Nokia 4.2 GDN 728x90.jpg" alt="Nokia" /> </a>
          <a class="footclose closebtn" href="#">CLOSE</a>
        </div> */}
        {/* <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={initialStep}
          onExit={this.onExit}
        /> */}

        {/* <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content" style={{ backgroundColor: '#f9f9f9', borderRadius: ".8rem"}}>
              <div className="modal-body text-center px-5 pb-5">
              <p role="button" className="text-right" onClick={this.closeModal} style={{ color: '#333', fontSize: 24, }}>&times;</p>
                <img src='/assets/images/840_x_323Artboard_4.jpg' alt="banner_e" className="mb-3" width="150" height="150" />
                <h2 style={{ color: '#333'}} className="font-weight-bold mb-3">We've Got some exciting news </h2>
                <p style={{ color: '#333'}}>We are launching our new app soon. Sign up to get notified and get one month of free Box Office Subscription.</p>
                <form onSubmit={this.addToNotificationList}>
                  <input type="email" name="email" placeholder="Enter your email address" className="mb-4" />
                  <button type="submit" className="btn btn-primary mb-3 px-5 py-4" style={{ backgroundColor: '#03a84e', border: 0, fontSize: 16}}>Sign me up!</button>
                </form>
              </div>
            </div>
          </div>
        </div> */}
         <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content" style={{ backgroundColor: 'transparent'}}>
              <div className="modal-body text-center">
              <p role="button" className="text-right" onClick={this.closeModal} style={{ color: 'white', fontSize: 30, fontWeight: 'bolder' }}>&times;</p>
                <a href='https://nairabox.com/app' target='_blank'>
                  <img src='https://res.cloudinary.com/dhtgrrnfg/image/upload/v1667573068/1_hpffqk.png' alt="banner_e" style={{ width: "100%"}} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({ user }, dispatch);
};
const mapStateToProps = (state, ownProps) => {
  let user = state.user;
  return {
    userFresh: user.user
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
