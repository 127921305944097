import { SIGNUP_PENDING, SIGNUP_SUCCESS, SIGNUP_FAILED } from '../actions/types';

const INITIAL_STATE = {
    fetching: false,
    fetched: false,
    isSignedUp: false,
    response: {},
    error: {}
};

export function signupReducer(state = INITIAL_STATE, action) {

    switch (action.type) {
        case SIGNUP_PENDING: {
            return {
                ...state,
                fetching: true,
                fetched: false
            };
        }
        case SIGNUP_SUCCESS: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                isSignedUp: true,
                response: action.payload
            };
        }
        case SIGNUP_FAILED: {
            return {
                ...state,
                fetching: false,
                fetched: false,
                error: action.payload
            };
        }
        default:
            return {
                ...state
            }
    };
}

