import { GET_TICKET_SHOWTIME_PENDING, GET_TICKET_SHOWTIME_SUCCESS, GET_TICKET_SHOWTIME_FAILED } from '../actions/types';
let initialState = {
    fetching: false,
    fetched: false,
    showtime: {},
};
export default function ticketShowtimeReducer(state = initialState, action) {
    switch (action.type) {
        case GET_TICKET_SHOWTIME_PENDING:
            return { ...state, fetching: true, fetched: false };
        case GET_TICKET_SHOWTIME_SUCCESS:
            let showtime = action.payload;
            return { ...state, showtime, fetching: false, fetched: true };
        case GET_TICKET_SHOWTIME_FAILED:
            return { ...state, fetching: false, fetched: false };

        default: return { ...state };
    }
}