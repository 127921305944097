import { GET_MOVIE_PENDING, GET_MOVIE_SUCCESS, GET_MOVIE_FAILED } from '../actions/types';
let initialState = {
    fetching: false,
    fetched: false,
    movie: {},
};
export default function movieReducer(state = initialState, action) {
    switch (action.type) {
        case GET_MOVIE_PENDING:
            return { ...state, fetching: true, fetched: false };
        case GET_MOVIE_SUCCESS:
            let movie = action.payload;
            return { ...state, movie, fetching: false, fetched: true };
        case GET_MOVIE_FAILED:
            return { ...state, fetching: false, fetched: false };

        default: return { ...state };
    }
}