import React, { Component } from 'react';
import { connect } from 'react-redux';

import Footer from '../containers/includes/Footer';
import FooterTop from '../containers/includes/FooterTop';
import SingleMovieHeader from '../containers/SingleMovieHeader';
//import Nav from '../containers/includes/Nav';
import Nav from '../smarts/MoviesDataProvider';


import TicketForms from './TicketForms';

import { getMovie } from '../redux/actions/movie'
class SingleMovie extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)

    let { dispatch } = this.props;
    //let { id } = this.props.location.state;
    const ID = this.props.match.params.slug
    dispatch(getMovie(ID));
  }

  render() {
    let { title, description } = this.props.movie;
    let { movie, cinemas, dispatch, history, location, match } = this.props;
    return (
      <div>
        <Nav />
        <main>
          <SingleMovieHeader artwork={movie.bannerImage} title={title} />
          <div className="container section-separator">
            <div className="row movie">
              <TicketForms title={title} description={description} movie={movie}
                cinemas={cinemas} movieId={movie.id} dispatch={dispatch}
                history={history} location={location} match={match} />
            </div>
          </div>
          <FooterTop />
          <Footer />
        </main>
      </div>
    )
  }
}

function mapStateToProps(state) {
  let { movie } = state.movie;
  let { cinemas } = movie;
  return {
    movie,
    cinemas
  }
}

export default connect(mapStateToProps)(SingleMovie);