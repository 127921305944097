import React, { Component } from "react";
import Footer from "./includes/Footer";
import FooterTop from "./includes/FooterTop";
import Nav from "./includes/Nav";

class DriveInTerms extends Component {
  render() {
    return (
      <div>
        <Nav />
        <main style={{ marginTop: "10rem" }}>
          <div className="container">
            <div className="terms">
              <h1>Nairabox drive-in terms and conditions</h1>
              <p>
                <h2>NAIRABOX DRIVE-IN TERMS AND CONDITIONS</h2>

                <h3>TERMS & CONDITIONS. </h3>
                <p>
                  Please take a moment to Review Our House Rules to Better
                  Protect Everyone’s Experience. Our rules are here for your
                  safety as well as the safety of others and our staff. We want
                  to ensure everyone in attendance has a safe & enjoyable
                  evening. Failure to follow these and or other rules for the
                  Theater can and will result in ejection from the Theater
                  without a refund.
                </p>
                <h3>MOVIE TIMES & INFORMATION.</h3>
                <p>
                  Visit our website at movies.nairabox.com Follow us on
                  Facebook, twitter and Instagram for our postings and events.
                  Follow us on @nairabox E-Mail us at support@nairabox.com
                </p>
                <h3>MOVIE PROGRAMMING & BOOKINGS.</h3>
                <p>
                  Second run movies will most likely show can run for a matter
                  of days or weeks depending on the contract. We try to play and
                  put the strongest movies on the screen we can get. We aren’t
                  going to terminate a movie that is bringing in lots of people
                  unless there is something stronger that we missed out on
                  earlier, or something better coming out. If there is something
                  strong that we missed, we usually bring it in at the first
                  opportunity. We always play at least one movie a day. These
                  drive-in tickets are people oriented, some theaters gravitate
                  towards different demographics, some only families, some only
                  teenage movies, some older style movies. We play everything
                  that is on the top of the box office charts so that everyone
                  can catch their movie at the drive-in. We try to get what the
                  majority want to see. We play mostly G, PG, PG-13, 18.
                  REMEMBER THAT CHILDREN ARE NOT ALLOWED. ANYONE WITH A CHILD
                  WILL NOT BE GRANTED ADMISSION AND WILL NOT GET A REFUND.
                </p>
                <h3>ADMISSIONS.</h3>
                <p>
                  We do accept MOST major credit cards (Visa, Master Card,
                  Verve) and of course cash, cash is always good. Please hold
                  onto all tickets you are given at the gate. You will need
                  these in case you are approached by security. These tickets
                  are your pass into the theater, do not lose them. If for any
                  reason you leave the theater, you MUST pay to re-enter if you
                  cannot provide the attendant at the gate with your tickets.
                  You must pay per head, for everyone in the car. If you are
                  caught sneaking in, this is considered theft and will be
                  treated as such.
                </p>
                <h3>REFUNDS / RAIN CHECKS.</h3>
                <p>
                  Refunds are given at the manager’s discretion. If a severe
                  thunder storm or heavy rain occurs, making it impossible for
                  you to stay and enjoy the movie, we will give you a rain check
                  if you haven’t been able to see at least 30 mins of the movie.
                  Be sure to hold onto your tickets. We can’t offer you a rain
                  check if you don’t have your tickets. If a power outage or
                  such act of nature, or technical difficulty beyond our control
                  occurs, we will offer passes, and or rain checks at which it
                  is your choice which you prefer to accept. The passes will be
                  good until the date the manager chooses usually not less than
                  30 days.
                </p>
                <h3>PARKING.</h3>
                <p>
                  PLEASE pay particular attention to the information you receive
                  at the gate which pertains to our PARKING POLICY. If you have
                  a Large truck, Van, or SUV, chances are you will not have to
                  park all the way in the back anymore depending how big your
                  vehicle is. We have a specifically designed spaces that allows
                  different size vehicles parking access all the way to the
                  front of the screen. Make sure you park where your told to at
                  the gate, pay close attention. Each vehicle is entitled to one
                  parking space. If you sit outside your vehicle, please do not
                  occupy another parking space, this includes your chairs and
                  blankets. We do not allow anyone to sit on top of their
                  vehicle for any reason. This is a big safety concern. NO
                  EXCEPTIONS!
                </p>
                <h3>ON THE GROUNDS.</h3>
                <p>
                  Drivers are responsible for making sure that all passengers
                  are inside the vehicle and that no other patrons are in the
                  way before moving their vehicle or leaving the theater. We do
                  allow walk-ins. We ask that you arrive early as not to
                  interrupt the show after start time.
                </p>
                <h3>SPEAKER & RADIO SOUND.</h3>
                <h3>WATCHING THE SHOW.</h3>
                <p>
                  If you must talk please do not carry on a loud conversation(s)
                  or cause a disturbance. We suggest that you turn the ringer on
                  your phone down, and if you must use it please talk quietly or
                  walk away as to not bother your neighbors. We ask that any
                  parties or gatherings you may be planning to have should hold
                  on the grounds due to the social distancing rule and as not to
                  distract our other guests or cause disturbances.
                </p>
                <h3>CONCESSION STAND.</h3>
                <p>
                  Your ticket comes with popcorn, drink and a mask. Feel free to
                  check with the staff what other options you can purchase.
                </p>
                <h3>PROHIBITED.</h3>
                <p>
                  Do not throw objects of any kind at the screen, buildings,
                  cars, people, or at anything else for that matter. Climbing on
                  the screen towers is strictly prohibited and off limits.
                  Children are not allowed Waving in front of the projection
                  path is prohibited and off limits. The equipment is delicate
                </p>
                <h3>PERSONAL PROPERTY/PERSONAL INJURY & LOSS.</h3>
                <p>
                  We are NOT RESPONSIBLE for ANY, ALL, PART OF, WHATSOEVER, ON
                  OUR LOTS. We are NOT RESPONSIBLE for Motor Vehicle accidents.
                  We are NOT RESPONSIBLE for damage to vehicles or personal
                  property. We are NOT RESPONSIBLE for lost or stolen items
                  including, money & personal property. We are NOT RESPONSIBLE
                  for Medical injuries & accidents. FROM THE STAFF & MANAGEMENT
                  OF THE SOUTH DRIVE-IN THEATER – THANK YOU! ENJOY YOUR EVENING
                  AT THE DRIVE-IN! SIT BACK AND ENJOY THE SHOW!
                </p>
              </p>
            </div>
          </div>
          <FooterTop />
          <Footer />
        </main>
      </div>
    );
  }
}

export default DriveInTerms;
