import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import randomString from 'randomstring';
import { PhoneNumberUtil } from 'google-libphonenumber';

import Timer from '../reusables/Timer';
import '../assets/css/validation.css';

class TicketDetailsForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tickets: [],
			ticketInputs: [],
			ticketInfo: {},
			customTicketForm: "",
			customTicketFormSingle: "",
			duplicateOption: "single",
			totalTickets: 0,
			isInvalid: false,
			inValidInputs: [],
		};

		this.phoneRef = React.createRef();
		this.fullnameRef = React.createRef();

	}

	_VALIDATE_NG_MSISIDN = (msisdn) => {
		
		var telcoPrefixes = [703, 706, 803, 806, 810, 813, 814, 816, 903, 705, 805, 811, 815, 905, 701, 708, 802, 808, 812, 902, 809, 817, 818, 909, 804];
		// var dialingCode, mobilePrefix, checkArray;
		var inputLength = msisdn.length;
		const in_array = (value, array) => {
			var index = array.indexOf(value);
			if (index == -1) {
				console.log('1')
				return false;
			} else {
				return index;
			}
		}

		if (inputLength < 11) {
			if (inputLength === 10){
				let firstFigure = Number(msisdn[0]);
				let mobilePrefix = Number(msisdn.substr(0, 3));
				if (firstFigure === 0){
					console.log('2')
					return false;
				}else if(in_array(mobilePrefix, telcoPrefixes)){
					console.log('3')
					return true
				}else{
					return false;
				}
			}else{
				console.log('5')
				return false;
			}
		} else if (inputLength === 11) {
			let mobilePrefix = Number(msisdn.substr(1, 3));
			let firstFigure = Number(msisdn[0]);
			let checkArray = in_array(mobilePrefix, telcoPrefixes);
			if (checkArray === false) {
				console.log("6")
				return false
			} else if (checkArray > 0 && firstFigure === 0) {
				console.log('7')
				return true
			} else {
				console.log('8')
				return false
			}
		} else if (inputLength === 13) {
			let mobilePrefix = Number(msisdn.slice(4, 7));
			let dialingCode = msisdn.slice(0, 4);
			let checkArray = in_array(mobilePrefix, telcoPrefixes);
			if (checkArray === false) {
				console.log('9')
				return false;
			} else if ((checkArray >= 0) && (dialingCode === "+234")) {
				console.log('10')
				return true;
			} else {
				console.log('11')
				return false;
			}
		}else if(inputLength === 14 || inputLength === 15){

			//get mobile number prefix from entered value
			let mobilePrefix = inputLength === 14 ? Number(msisdn.slice(4,7)) : Number(msisdn.slice(5, 8))

			//get dialling code from mobile number - +234
			let dialingCode = msisdn.slice(0,4);

			//check if prefix exists in mobile prefix array
			let checkArray = in_array(mobilePrefix, telcoPrefixes);

			//if prefix not found in array
			if(checkArray === false){
				console.log('13')
				return false

			//if found in array
			}else if((checkArray >= 0) && (dialingCode === "+234")){
				
				return true

			}else{
				console.log('14')
				return false
			}
		 } else if (inputLength > 14) {
			console.log('12')
			return false;
		}

	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({ [name]: value });


		if (name === 'ticketType') {

		}
	}
	
	handleRevealRadio = (e) => {
		let { value } = e.target;
		//('radio value: ', value);
		this.setState({ duplicateOption: value })

		this.setState({ ticketInputs: [] });
		//('radio target: ', e.target);

	}

	handleFormInputChange = (index, key) => {
		// const { name, value } = e.target;
		// //("index: ", index);

		//******** */
		// //("index", index); //E.g xyz
		// //("val", key.target.value); //E.g tunmbi
		// let inputVal = key.target.value; //E.g tunmbi
		// //("name", key.target.getAttribute("data-inputname")); //E.g name, email
		// let inputName = key.target.getAttribute("data-inputname");

		// const formKey = { ...this.state[index] };
		// formKey[inputName] = inputVal;

		// this.setState({ [index]: formKey });
		//******** */


		// Copy out state ticketInputs
		const { name, value } = key.target;
		let { ticketInputs } = this.state;
		let fieldName = name;
		let fieldValue = value;
		let ticketType = key.target.getAttribute("data-tickettype");
		let price = key.target.getAttribute("data-price");
		let id = key.target.getAttribute("data-id");


		this.forceUpdate();
		//console.log('id in forminputchange: ', id);

		//start validation
		//console.log(`name: ${name}, value: ${value}`);



		//end validation


		let new_item = {
			uid: index,
			[fieldName]: fieldValue,
			ticketType,
			price,
			ticketTypeId: id
		};

		// Check if ticketInputs array already has an object with this uid. If you, push to it
		const indexOfItemInArray = ticketInputs.findIndex(
			ticket => ticket.uid === index
		);
		// If it exists already...
		if (indexOfItemInArray > -1) {
			let newObject = { ...ticketInputs[indexOfItemInArray], ...new_item };
			ticketInputs.splice(indexOfItemInArray, 1, newObject);
		} else {
			ticketInputs.push(new_item);
		}

		this.setState({
			ticketInputs 
		});


		this.forceUpdate();

	}
	handleBlur = (e) => {
		let { name, value } = e.target;
		if (name === 'phone') {
			console.log(this._VALIDATE_NG_MSISIDN(value.trim()))
			if (this._VALIDATE_NG_MSISIDN(value.trim())){
				if (value.trim() === '') {
					this.setState({ isInvalid: true });
					this.phoneRef.current.classList.add('y-invalid');
				}
				else {
					if (value.length > 11) {
						let prefix = value[0] == '0' ? '' : '0';
						const formatPhone = value.replace(/\D/g, '').replace(/^234/, prefix);
						console.log(formatPhone)
						this.setState({ [name]: value });
						this.phoneRef.current.value = formatPhone;
	
					}
					this.phoneRef.current.classList.remove('y-invalid');
				}
			}else{
				this.setState({ isInvalid: true });
				this.phoneRef.current.classList.add('y-invalid');
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		//console.log('showMultiple duplicateOption: ', (prevState.duplicateOption === "multiple"))
		let ticketInputNInfo = {
			ticketInputs: prevState.ticketInputs,
			totalTickets: prevState.totalTickets, ticketInfo: prevState.ticketInfo,
			showMultiple: prevState.duplicateOption === "multiple",
		}
		//send to BuyTicketDetails
		this.props.sendValues2Parent('details', ticketInputNInfo);
		if (prevState.duplicateOption !== this.state.duplicateOption) {
			this.showForms();
			this.showOneForm();
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		this.showOneForm();

		// Get total number of tickets. Loop through array selectedtickets and add their quantities
		let { selectedTickets } = this.props;
		const totalTickets = selectedTickets.reduce((accummulator, num) => accummulator + Number(num.quantity), 0);
		this.setState({
			totalTickets
		});
		console.log("vvvvvvvvvvvvvvv",this.props.ticketFormState)
	}

	showForms = () => {
		let { selectedTickets, ticketFormState } = this.props;
		let customTicketForm = selectedTickets.map((item, key) => {
			return this.numberArrayLessOne(item.quantity).map(itm => {
				return this.singleTicketForm(item.type, item.price, item.ticketTypeId);
			});
		});

		//("customTicketForm", customTicketForm);

		this.setState({
			customTicketForm,
			ticketInfo: {
				cinemaUID: ticketFormState.selectedCinemaId,
				showTime: ticketFormState.selectedShowTimeID,
				filmhouseShowtimeId: ticketFormState.filmhouseShowtimeId,
			}
		});
	}

	showOneForm = () => {
		
		let { selectedTickets, ticketFormState } = this.props;

		// if (secondaryPrice > 0){
		// 	console.log("THe movie")
		// 	let formTicktet = (<div className="row mt-4" key={0}>
		// 		<div className="col-md-12">
		// 			<div className="ticket-buyer__form p-4">
		// 				<div className="row mb-4 ticket-buyer__type">
		// 					<div className="col-md-6 capitalize">{"ticketType"}</div>
		// 					<div className="col-md-6 text-right">₦ {secondaryPrice}</div>
		// 				</div>
		// 				<div className="form-row">
		// 					<div className="form-group col-md-4">
		// 						<label htmlFor="fullname2">Full Name</label>
		// 						<input type="text"
		// 							className="form-control"
		// 							ref={this.fullnameRef}
		// 							id="fullname2" data-tickettype={"ticketType"} data-price={secondaryPrice}
		// 							data-id={"id"} onBlur={this.handleBlur}
		// 							value={this.state[`fullname`]} onChange={this.handleFormInputChange.bind(null, 0)} name={`fullname`} placeholder="Full Name" />
		// 						{/* <input type="text" className="form-control" id="fullname2" data-inputname="name" value={this.state[`fullname-${somekey}`]} onChange={this.handleFormInputChange.bind(null, somekey)} name={`fullname`} placeholder="Full Name" /> */}
		// 					</div>
		// 					<div className="form-group col-md-4">
		// 						<label htmlFor="phone2">Phone Number</label>
		// 						<input type="tel"
		// 							className="form-control"
		// 							ref={this.phoneRef}
		// 							id="phone2" data-tickettype={"ticketType"} data-price={secondaryPrice}
		// 							data-id={"id"} onBlur={this.handleBlur}
		// 							data-inputname="phone" value={this.state[`phone`]} onChange={this.handleFormInputChange.bind(null, 0)} name={`phone`} placeholder="08012345678" />
		// 					</div>
		// 					<div className="form-group col-md-4">
		// 						<label htmlFor="email2">Enter Email</label>
		// 						<input type="email" className="form-control" id="email2" data-tickettype={"ticketType"}
		// 							data-id={"id"} onBlur={this.handleBlur}
		// 							data-price={secondaryPrice} data-inputname="email" value={this.state[`email`]} onChange={this.handleFormInputChange.bind(null, 0)} name={`email`} placeholder="me@email.com" />
		// 					</div>
		// 				</div>
		// 			</div>
		// 		</div>
		// 	</div>);

		// 	this.setState({customTicketFormSingle: formTicktet});
		// }else if(price > 0){

			let customTicketFormSingle = this.singleTicketForm(selectedTickets[0].type,
				selectedTickets[0].price, selectedTickets[0].ticketTypeId);
			this.setState({
				customTicketFormSingle,
				ticketInfo: {
					cinemaUID: ticketFormState.selectedCinemaId,
					showTime: ticketFormState.selectedShowTimeID,
					filmhouseShowtimeId: ticketFormState.filmhouseShowtimeId,
				}
			});
		// }
	}



	numberArrayLessOne(num) {
		const num1 = Number(num);
		const numArr = [];
		for (let i = 1; i <= num1; i++) {
			numArr.push(i);
		}
		return numArr;
	}

	singleTicketForm = (ticketType, price, id) => {
		let somekey = randomString.generate({ length: 4 });

		//console.log('id in singleTicketForm: ', id)

		// this.state.tickets.push(somekey);
		//(this.state.isFirstForm);
		let isIncluded = this.state.inValidInputs.includes('phone');
		console.log('isIncluded: ', isIncluded);

		let toReturn = (<div className="row mt-4" key={somekey}>
			<div className="col-md-12">
				<div className="ticket-buyer__form p-4">
					<div className="row mb-4 ticket-buyer__type">
						<div className="col-md-6 capitalize">{ticketType}</div>
						<div className="col-md-6 text-right">₦ {price}</div>
					</div>
					<div className="form-row">
						<div className="form-group col-md-4">
							<label htmlFor="fullname2">Full Name</label>
							<input type="text"
								className="form-control"
								ref={this.fullnameRef}
								id="fullname2" data-tickettype={ticketType} data-price={price}
								data-id={id} onBlur={this.handleBlur}
								value={this.state[`fullname`]} onChange={this.handleFormInputChange.bind(null, somekey)} name={`fullname`} placeholder="Full Name" />
							{/* <input type="text" className="form-control" id="fullname2" data-inputname="name" value={this.state[`fullname-${somekey}`]} onChange={this.handleFormInputChange.bind(null, somekey)} name={`fullname`} placeholder="Full Name" /> */}
						</div>
						<div className="form-group col-md-4">
							<label htmlFor="phone2">Phone Number</label>
							<input type="tel"
								className="form-control"
								ref={this.phoneRef}
								id="phone2" data-tickettype={ticketType} data-price={price}
								data-id={id} onBlur={this.handleBlur}
								data-inputname="phone" value={this.state[`phone`]} onChange={this.handleFormInputChange.bind(null, somekey)} name={`phone`} placeholder="08012345678" />
						</div>
						<div className="form-group col-md-4">
							<label htmlFor="email2">Enter Email</label>
							<input type="email" className="form-control" id="email2" data-tickettype={ticketType}
								data-id={id} onBlur={this.handleBlur}
								data-price={price} data-inputname="email" value={this.state[`email`]} onChange={this.handleFormInputChange.bind(null, somekey)} name={`email`} placeholder="me@email.com" />
						</div>
					</div>
				</div>
			</div>
		</div>);

		return toReturn;
	}


	render() {
		let { ticketFormState, price, selectedTickets } = this.props;
		let { locationsObjs } = ticketFormState;
		let selectedLocObj = locationsObjs && locationsObjs.filter((item) => item.id === ticketFormState.location)[0]
		//console.log('selectedLocObj: ', selectedLocObj)
		//('ticketDetailsForm props: ', this.props);
		//('ticketFormState', ticketFormState);
		//('selectedTickets', selectedTickets);





		return (
			<div className="col-md-8" id="ticket-details-form">
				<div className="row mb-4">
					<div className="col-12 col-md-12">
						<div className="section-title">Movie Ticket Details</div>
					</div>
				</div>

				<div className="row mb-4">


					{ticketFormState.selectedShowtime && ticketFormState.selectedShowtime.location ?
					(<div className="col-12 col-md-6">
					<div className="ticket-buyer__details">
						<p className="ticket-buyer__type">Location</p>
						<p>{ticketFormState.selectedShowtime.location}</p>

					</div>
					</div>)
					: (<div className="col-12 col-md-6">
						<div className="ticket-buyer__details">
							<p className="ticket-buyer__type">Cinema Location</p>
							<p>{selectedLocObj.name}</p>

						</div>
					</div>)
					}


					<div className="col-12 col-md-6">
						<div className="ticket-buyer__details">
							<p className="ticket-buyer__type">Date & Time</p>
							{/* <p>Saturday, 16th March - 2:30 PM</p> */}
							<p>
								{ticketFormState.date} - {ticketFormState.time}
							</p>
						</div>
					</div>
				</div>

				<div>
					<div className="text-center mt-5 pt-4 pb-4">
						<h2>Reservation expires in:
              <span className="timer-thing"> <Timer /*mins={3}*/ />
							</span>
						</h2>
					</div>
				</div>

				<div className="row">
					<div className="col-12 col-md-12">
						<div className="ticket-buyer__details">
							<p className="ticket-buyer__radio">
								{/* <span className="ticket-buyer__expiry">ticket expires in: </span><span className="ticket-buyer__time">20:00</span> */}
								{/* <span>
                  <input type="radio" name="duplicateOption" value="single"
                    checked={this.state.duplicateOption === "single"}
                    onChange={this.handleRevealRadio} /> Use Single
Information for all tickets
                </span> */}
								{/* <span>
                  <input type="radio" name="duplicateOption" value="multiple"
                    checked={this.state.duplicateOption === "multiple"}
                    onChange={this.handleRevealRadio} /> Buying multiple tickets?
                </span> */}
							</p>
						</div>
					</div>
				</div>

				{(this.state.duplicateOption === "multiple") ? this.state.customTicketForm : this.state.customTicketFormSingle}
			</div>
		);
	}
}

function mapStateToProps(state) {
	let { showtime } = state.showtime;
	return {
		showtime
	}
}

function mapDispatchToProps(dispatch, ownProps) {
	return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketDetailsForm);