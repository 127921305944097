import React, { Component } from 'react'

class Login extends Component {
  render() {
    return (
      <div className="login">
        <div className="row my-4">
          <a href="/"><img src="../assets/images/nairabox-logo.png" alt="Nairabox Logo" /></a>
        </div>

        <div className="row my-4 p-4">
          <div className="col-md-6 login__form">
            <div className="welcome-text">Create a movieVISA account</div>
            <div className="signup-text">Already signed up? <a href="/login">Login</a></div>
            <div className="row">
              <div className="col-md-6 login__form-input">
                <input type="text" placeholder="First Name" />
              </div>
              <div className="col-md-6 login__form-input">
                <input type="text" placeholder="Last Name" />
              </div>
              <div className="col-md-12 login__form-input">
                <input type="text" placeholder="Phone Number" />
              </div>
              <div className="col-md-12 login__form-input">
                <input type="text" placeholder="Enter your email address" />
              </div>
              <div className="col-md-12 login__form-input">
                <input type="password" placeholder="Password" />
              </div>
              <div className="col-md-12 login__form-input">
                <button className="ticket-button block-btn">Sign Up</button>
              </div>
            </div>
            <div className="forgot_password"><a href="#/somelink">Forgot Password?</a></div>
          </div>

          <div className="col-md-6 login__side">
            <div className="login__side-content">
              <img src="../assets/images/undraw_movie_night_93wl.png" alt="Login" />
              <div className="login__lead">All your movies for a small price</div>
              <div className="">This subscription allows you to access any movie of your choice</div>
            </div>
          </div>
        </div>

        <div className="row my-4"><img src="../assets/images/powered_by.png" alt="Powered by VISA" /></div>
      </div>
    )
  }
}

export default Login;