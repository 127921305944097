import React, { Component } from 'react';
import TicketDetailsForm from '../components/TicketDetailsForm';
import TicketDetailsSummary from '../components/TicketDetailsSummary';
class SelectRefreshments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailsFormValues: null,
      detailsSummaryValues: null,
    }
    this.getValuesFromChild = this.getValuesFromChild.bind(this);
  }
  getValuesFromChild(detailsOrSummary, theValues) {
    if (detailsOrSummary === 'summary') {
      this.setState({ detailsSummaryValues: theValues })
    }
    else {
      this.setState({
        detailsFormValues: theValues.ticketInputs,
        totalTickets: theValues.totalTickets, ticketInfo: theValues.ticketInfo,
        showMultiple: theValues.showMultiple,
      });
    }
  }
  doFinalSubmit() {
    //get this.state.detailsFormValues and submit
  }
  render() {
    let { ticketFormState, movie } = this.props;
    let { filmhouse_id, genesis_id } = movie;
    //console.log('filmhouse_id: ', filmhouse_id);
    let { selectedTicketTypesWithQty, price, location, selectedShowtime, carType } = ticketFormState

    return (
      <div className="container section-separator">
        <div className="row movie mt-5">
          <TicketDetailsForm selectedTickets={selectedTicketTypesWithQty}
            sendValues2Parent={this.getValuesFromChild} ticketFormState={ticketFormState} />

          <TicketDetailsSummary selectedTickets={selectedTicketTypesWithQty}
            sendValues2Parent={this.getValuesFromChild} price={price}
            ticketInfo={this.state.ticketInfo} totalTickets={this.state.totalTickets}
            formValues={this.state.detailsFormValues}
            cinemaId={location}
            //history={this.props.history}
            ticketFormState={ticketFormState}
            movie={movie}
            filmHouseId={filmhouse_id}
            genesisId={genesis_id}
            carType={carType}

            selectedShowtime={selectedShowtime}
            //ticketFormState4rmBuyTicketForm={this.state.ticketFormState4rmBuyTicketForm}
            showMultiple={this.state.showMultiple} />
        </div>
      </div>
    )
  }
}
export default SelectRefreshments;