import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'

import BuyTicketForm from './BuyTicketForm';
import SelectRefreshments from './SelectRefreshments'
import BuyTicketDetails from './BuyTicketDetails';
import MovieDescription from './MovieDescription';
class TicketForms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            details: 0,
            formObject: '',
            ticketFormState: '',
        }

        this.scrollToRef = React.createRef();
        this.setComponent = this.setComponent.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.scrollToMyRef();
    }

    componentDidUpdate(prevState, prevProps) {

    }
    scrollToMyRef = () =>{
        window.scrollTo(0, this.scrollToRef.current.scrollIntoView())
    }

    setComponent(istrue, formObject, state, event) {
        //istrue is supposed to signify if the data are coming from details component or not
        //BuyTicketForm calls with:
        //this.props.toParent(true, formValues, this.state, e);
        this.setState({ details: 1 });
        this.setState({ formObject: formObject });
        this.setState({ ticketFormState: state });
    }

    render() {
        let props = this.props;
        let { match } = this.props;

        let thisUrl  = match.url;


        return (
            <div className="col-md-12" ref={this.scrollToRef}>
                <div className="container section-separator">

                        {this.state.details == 1 &&
                        (<div className="row y-buyticket-details mt-4 pt-4">
                            <BuyTicketDetails {...props}  ticketFormState={this.state.ticketFormState} />
                        </div>)
                        }

                        {this.state.details == 2 &&
                        (<div className="row y-buyticket-details mt-4 pt-4">
                        <SelectRefreshments {...props}  ticketFormState={this.state.ticketFormState} />
                        </div>)
                        }

                        {this.state.details == 0 &&
                        (<div className="row y-buyticket-form pt-5 mt-5">
                        <MovieDescription  {...props} /> <BuyTicketForm url={thisUrl} {...props} toParent={this.setComponent} />
                        </div>)
                        }

                </div>
            </div>
        )
    }
}

export default TicketForms;
