import {
    USER_PENDING, USER_SUCCESS, USER_FAILED, USER_CLEAR,
    USER_UNSUBSCRIBE_SUCCESS, USER_RESUBSCRIBE_SUCCESS, USER_SUBFAILURE
} from '../actions/types';

const INITIAL_STATE = {
    fetching: false, fetched: false, user: null, error: null,
    subStatus: null,

};

const user = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_PENDING: {
            return { ...state, fetching: true, fetched: false };
        }
        case USER_SUCCESS: {
            return {
                ...state, fetching: false, fetched: true,
                user: action.payload.user, error: null,
            };
        }
        case USER_UNSUBSCRIBE_SUCCESS: {
            return {
                ...state, fetching: false, fetched: true,
                user: action.payload, error: null, subStatus: 'unsubscribed'
            };
        }
        case USER_RESUBSCRIBE_SUCCESS: {
            return {
                ...state, fetching: false, fetched: true,
                user: action.payload, error: null, subStatus: 'resubscribed'
            };
        }
        case USER_SUBFAILURE: {
            return {
                ...state, fetching: false, fetched: true,
                user: null, error: action.payload, subStatus: 'error'
            };
        }

        case USER_FAILED: {
            return { ...state, fetching: false, fetched: false, error: action.payload };
        }
        case USER_CLEAR: {
            return { ...state, user: null }
        }
        default: return { ...state }
    }
};
export default user;