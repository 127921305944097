import React, { Component, Suspense } from "react";
import Tour from "reactour";

function Nav() {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
      {/* <Suspense fallback={<React.Fragment />}>
          <Tour
            steps={steps}
            isOpen={isTourOpen}
            onRequestClose={this.setState(() => ({ isTourOpen: false }))} />
        </Suspense> */}
      <div className="container">
        <a className="navbar-brand" href="/">
          <img
            className="mainlogo"
            src="../assets/images/nairabox_white.png"
            width="163px"
            height="50px"
            alt="Nairabox Logo"
          />
        </a>

        {/* <div class="nav__search">
            <i class="fa fa-search icon"></i>
            <input type="text" class="nav__search-input" name="search" placeholder="Search for movie" id="" />
          </div> */}

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse navbar-collapse nav-menu"
          id="navbarResponsive"
        >
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link" href="/movies">
                Movies
              </a>
            </li>
            {/* <li className="nav-item">
                <a className="nav-link" href="/moviesubscription">Movie Subscription</a>
              </li> */}
            {/* <li className="nav-item">
                  <a className="nav-link" href="/">Cinemas</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/">Login</a>
                </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}

const steps = [
  {
    selector: ".mainlogo",
    content: "This is my first Step"
  }
];

export default Nav;
