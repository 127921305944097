import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import { getUserFromLocal } from './localUser';

// async function getFromLocal() {
//     let user = await getUserFromLocal();
//     return user;

// }
//let { from } = this.props.location.state || { from: { pathname: "/" } };
export function PrivateRoute({ component: Component, ...rest }) {
    let user = getUserFromLocal();
    console.log('user in PrivateRouter: ', user);

    return (
        <Route
            {...rest}
            render={props =>
                user.user ? (
                    <Component user={user} {...props} />
                ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    )
            }
        />
    );
}