import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login } from '../redux/actions/login';

import Login from '../components/Login'

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({ login }, dispatch)
}
const mapStateToProps = (state, ownProps) => {
  let login = state.login;
  console.log('login state: ', login)
  return {
    loginData: login,
    user: login.user,
    //user: login.user? login.user: null,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)
