import { GET_CINEMAS_PENDING, GET_CINEMAS_SUCCESS, GET_CINEMAS_FAILED } from '../actions/types';
let initialState = { fetching: false, fetched: false, cinemas: [] };

export default function cinemasReducer(state = initialState, action) {
    switch (action.type) {
        case GET_CINEMAS_PENDING:
            return { ...state, fetching: true, fetched: false };
        case GET_CINEMAS_SUCCESS:
            return { ...state, cinemas: action.payload, fetching: false, fetched: true };
        case GET_CINEMAS_FAILED:
            return { ...state, fetching: false, fetched: false };
        default: return { ...state };
    }
}