import React, { Component } from 'react';

import { register } from '../redux/actions/register';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      password: '',
      token: '',
      user: null,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { registerData } = this.props;
    if (registerData && registerData.user) {
      let { user } = registerData;
      let { token } = user;
      let prevToken = prevProps.registerData.user ? prevProps.registerData.user.token : null;

      if (token && (token !== prevToken)) {
        this.setState({ token: token })
        this.setState({ user: user });
        localStorage.setItem('moviesubscription:token', token);
        let serializedUser = JSON.stringify(user);
        localStorage.setItem('moviesubscription:user', serializedUser);
        this.props.history.push('/moviesubscription')
      }
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  }
  handleSubmit = () => {
    //console.log(`submitting... , ${JSON.stringify(this.state, null, 4)}`);
    this.props.register(this.state);
  }
  render() {
    let { registerData } = this.props;
    //console.log('registerData: ', registerData)
    let { user, isRegistered, error } = registerData;
    return (
      <div className="login">
        <div className="row my-4"><a href="/"><img src="../assets/images/nairabox-logo.png" alt="Nairabox Logo" /></a></div>

        <div className="row my-4 p-4 authpages">
          <div className="col-md-6 register__form">
            <div className="register-width">
              <div className="welcome-text">Create a movieVISA account</div>
              {error && <p>error signing you up: {error.message}. Please try again</p>}
              {/* {(registerData.user && error === null) ? null : <p>error signing you up. Please try again</p>} */}
              <div className="signup-text">Already signed up? <a href="/login">Login</a></div>
              <div className="row">
                <div className="col-md-6 login__form-input">
                  <input type="text" name="first_name"
                    value={this.state.first_name}
                    onChange={this.handleInputChange}

                    placeholder="First Name" />
                </div>
                <div className="col-md-6 login__form-input">
                  <input type="text" name="last_name"
                    value={this.state.last_name}
                    onChange={this.handleInputChange}
                    placeholder="Last Name" />
                </div>
                <div className="col-md-12 login__form-input">
                  <input type="text" name="phone"
                    value={this.state.phone}
                    onChange={this.handleInputChange}
                    placeholder="Phone Number" />
                </div>
                <div className="col-md-12 login__form-input">
                  <input type="text" name="email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    placeholder="Enter your email address" />
                </div>
                <div className="col-md-12 login__form-input">
                  <input type="password" name="password"
                    value={this.state.password}
                    onChange={this.handleInputChange}
                    placeholder="Password" />
                </div>
                <div className="col-md-12 login__form-input">
                  <button onClick={this.handleSubmit} className="ticket-button block-btn">Sign Up</button>
                </div>
              </div>
              <div className="">By continuing, you accept our <a href="#/somelink">Terms of use</a></div>
            </div>
          </div>

          <div className="col-md-6 login__side">
            <div className="login__side-content">
              <img src="../assets/images/undraw_movie_night_93wl.png" alt="Login" />
              <div className="login__lead">All your movies for a small price</div>
              <div className="">This subscription allows you to access any movie of your choice</div>
            </div>
          </div>
        </div>

        {/* <div className="row my-4"><img src="../assets/images/powered_by.png" alt="Powered by VISA" /></div> */}
      </div>
    )
  }
}

export default Register;