import React, { Component } from 'react';
import Footer from './includes/Footer';
import FooterTop from './includes/FooterTop';
import Nav from './includes/Nav';

class Contact extends Component {
  render() {
    return (
      <div>
        <Nav />
        <main style={{marginTop: "10rem"}}>
          Contact Page
          <FooterTop />
          <Footer />
        </main>

      </div>
    )
  }
}

export default Contact;