import React, { Component } from 'react'

export default class TopMovies extends Component {
  render() {
    return (
      <div className="row my-4">
        <div className="col-md-3 text-center movie__others">
          <img src="assets/images/dragon.png" alt="" className="img-responsive" />
        </div>
        <div className="col-md-3 text-center movie__others">
          <img src="assets/images/glass.png" alt="" className="img-responsive" />
        </div>
        <div className="col-md-3 text-center movie__others">
          <img src="assets/images/levi.png" alt="" className="img-responsive" />
        </div>
        <div className="col-md-3 text-center movie__others">
          <img src="assets/images/theupside.png" alt="" className="img-responsive" />
        </div>
      </div>
    )
  }
}
