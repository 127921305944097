import React, { Component } from 'react';
import { connect } from 'react-redux'
import '../assets/css/loader.css';

class Loader extends Component {
    //checking for available seats

    render() {
        let { loading, loadingText } = this.props;
        return (<div className={loading ? "y-loader" : "y-loader-hidden"}>
            <div>
                <p><img src="../assets/images/spinner.svg" alt="loader" /></p>
                <p className="y-loader-text">{loadingText}</p>
            </div>
        </div>)
    }
}

function mapStateToProps(state) {
    //('state.loading: ', state.loading);
    let { loading, loadingText } = state.loading;
    return {
        loading, loadingText
    }
}

export default connect(mapStateToProps)(Loader);