import React, { Component } from 'react';
import OtherMovies from '../containers/OtherMovies';
import ModalVideo from 'react-modal-video';

class MovieDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      isOpen: false
    };

    this.openModal = this.openModal.bind(this)
  }

  openModal() {
    this.setState({ isOpen: true })
  }

  render() {
    let { title, description, movie, youtubeid } = this.props;

    return (
      <div className="col-md-8 ticket-md-subscription">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="section-title">Synopsis</div>
          </div>

          <div className="col-12 col-md-4 movie__thumbnail pt-5">
            <div className="overlay-card1">
              <img src={movie.artworkThumbnail} alt="" />
              <div className="middle1">
                <div className="overlaycontent">
                  <div
                    className="featured-ticket"
                  // onClick={this.toggle}
                  >
                    <svg
                      width="68"
                      height="68"
                      viewBox="0 0 68 68"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={this.openModal}
                    >
                      <path
                        className="bg1"
                        d="M34 68C52.7777 68 68 52.7777 68 34C68 15.2223 52.7777 0 34 0C15.2223 0 0 15.2223 0 34C0 52.7777 15.2223 68 34 68Z"
                        fill="#72AA52"
                        fillOpacity="0.66"
                      />
                      <path
                        className="bg2"
                        d="M34 63C50.0163 63 63 50.0163 63 34C63 17.9837 50.0163 5 34 5C17.9837 5 5 17.9837 5 34C5 50.0163 17.9837 63 34 63Z"
                        fill="#72AA52"
                      />
                      <path
                        className="play"
                        d="M49 34L27 49V34.273V19L49 34Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-8 pt-5">
            <p className="movie__title">{title}</p>
            <p className="movie__about">{description} </p>
            <p className="movie__rated">
              <strong>Rated:</strong> {movie.ageRestriction}
            </p>
            <p className="movie__time">
              <strong>Running Time:</strong> {movie.duration}
            </p>
            <p className="movie__starring">
              <strong>Starring:</strong> {movie.starring}
            </p>
          </div>
        </div>

        <div className="row mt-5 hidden-xs other-movies-section">
          <div className="section-title other-m"></div>
        </div>

        <div className="youtube">
          <ModalVideo
            channel="youtube"
            autoplay="true"
            ratio="16:9"
            isOpen={this.state.isOpen}
            videoId={movie.youtubeid}
            onClose={() => this.setState({ isOpen: false })}
          />
        </div>

        <OtherMovies />
      </div>
    );
  }
}

export default MovieDescription;