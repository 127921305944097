import React, { Component } from 'react';
import OtherMovieItem from '../smarts/OtherMovieItem';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getMovies } from '../redux/actions/movies';

class OtherMovies extends Component {

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(getMovies());
  }

  render() {
    let { movies } = this.props;

    // Shuffle the movies array and get the first 3 items
    const results = movies
       .sort(function () { return .5 - Math.random() })
      .slice(0, 1);

    let toRender = results.map(function (item, index, arr) {
      return <OtherMovieItem movie={item} key={item.id} />
    })

    return (
      <div className="row hidden-xs other-movies-section">
        {toRender}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    movies: state.movies.movies,
  }
}

export default withRouter(connect(mapStateToProps)(OtherMovies));