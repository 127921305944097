import { GET_MOVIES_PENDING, GET_MOVIES_SUCCESS, GET_MOVIES_FAILED, GET_MOVIES_FEATURED } from '../actions/types';
import { uniqueObjects } from '../../helpers'
let initialState = {
    fetching: false,
    fetched: false,
    movies: [],
    featured: [],
};
export default function moviesReducer(state = initialState, action) {
    switch (action.type) {
        case GET_MOVIES_PENDING:
            return { ...state, fetching: true, fetched: false };
        case GET_MOVIES_SUCCESS:
            // let movies = state.movies.concat(action.payload)
            return { ...state, movies: uniqueObjects(action.payload, 'id'), fetching: false, fetched: true };
        case GET_MOVIES_FAILED:
            return { ...state, fetching: false, fetched: false };
        case GET_MOVIES_FEATURED:
            let featured = uniqueObjects(action.payload, 'id');
            return { ...state, featured };

        default: return { ...state };
    }
}


