import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Nav from '../smarts/MoviesDataProvider';
import Header from '../containers/Header';
import Footer from '../containers/includes/Footer';
import FooterTop from '../containers/includes/FooterTop';
import TopMovies from '../containers/TopMovies';
import { Link } from 'react-router-dom'
import { user } from '../redux/actions/user';

class MovieSubscription extends Component {
  constructor() {
    super();

    this.state = {
      bannerTitle: "movieVisa",
      bannerText: "",
      bannerImageURL: ""
    }

  }
  render() {
    return (
      <div>
        <Nav location={this.props.location.pathname} />
        <main className="pricing-section" style={{'marginTop': '2rem'}}>
          <div className="container">
            <div className="section-title__no-underline text-center my-5">Choose Your movieVisa Experience Package</div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-4 offset-md-4">
                <div className="pricing-card">
                  <p className="text-center">Regular</p>
                  <p className="price text-center"><span className="price-num">N1, 000</span><span className="price-mth">/mo</span></p>
                  <p className="pricing-card__description pt-4">
                    <ul>
                      <li>Access to 3 movies in a month</li>
                      <li>Watch movies on weekdays between Mondays and Thursdays.</li>
                      <li>Only one ticket is redeemable per day</li>
                    </ul>
                  </p>
                  <p className="text-center mt-5"><Link to="/subscribe" className="light-btn pricing-btn">Get Started</Link></p>
                </div>
              </div>
            </div>
          </div>

          <div className="container section-separator moviesub">
            <div className="section-title__no-underline text-center my-5">How It Works</div>
            <div className="moviesub_steps">
              <ul>
                <li>
                  <h3>Select a package</h3>
                  <p>Select a package that best suits you and then create your account.</p>
                </li>
                <li>
                  <h3>Pay with Visa Card or Visa QRCode</h3>
                  <p>Pay with your Visa card or pay using using your VISA QRCode</p>
                </li>
                <li>
                  <h3>Select Movie</h3>
                  <p>Select movies to watch</p>
                </li>
              </ul>
            </div>

          </div>


          <div className="container">
            <div className="section-title__no-underline text-center my-5">Now Showing in the cinema</div>
          </div>


          <div className="container-fluid">
            <div className="row movies-cluster">
              <div className="movie-cluster__single col-2">
                <img src="../assets/images/movie-cards/card1.png" alt="movie-card" />
              </div>
              <div className="movie-cluster__single col-2">
                <img src="../assets/images/movie-cards/card2.png" alt="movie-card" />
              </div>
              <div className="movie-cluster__single col-2">
                <img src="../assets/images/movie-cards/card3.png" alt="movie-card" />
              </div>
              <div className="movie-cluster__single col-2">
                <img src="../assets/images/movie-cards/card4.png" alt="movie-card" />
              </div>
              <div className="movie-cluster__single col-2">
                <img src="../assets/images/movie-cards/card5.png" alt="movie-card" />
              </div>
              <div className="movie-cluster__single col-2">
                <img src="../assets/images/movie-cards/card6.png" alt="movie-card" />
              </div>
              <div className="movie-cluster__single col-2">
                <img src="../assets/images/movie-cards/card7.png" alt="movie-card" />
              </div>
              <div className="movie-cluster__single col-2">
                <img src="../assets/images/movie-cards/card8.png" alt="movie-card" />
              </div>
              <div className="movie-cluster__single col-2">
                <img src="../assets/images/movie-cards/card9.png" alt="movie-card" />
              </div>
              <div className="movie-cluster__single col-2">
                <img src="../assets/images/movie-cards/card10.png" alt="movie-card" />
              </div>
              <div className="movie-cluster__single col-2">
                <img src="../assets/images/movie-cards/card11.png" alt="movie-card" />
              </div>
              <div className="movie-cluster__single col-2">
                <img src="../assets/images/movie-cards/card12.png" alt="movie-card" />
              </div>
            </div>
          </div>

          <FooterTop />
          <Footer />
        </main>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({ user }, dispatch)
}
const mapStateToProps = (state, ownProps) => {
  let user = state.user;
  console.log('user state: ', user)
  return {
    user: user.user,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MovieSubscription)