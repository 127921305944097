import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import App from '../App';
import Home from './Home';
import SingleMovie from '../components/SingleMovie';
import MovieStatus from '../components/MovieStatus';
import SingleVivaMovie from '../components/SingleVivaMovies';
import SingleOoplMovie from '../components/SingleOoplMovies';
import NotFound from '../containers/NotFound';
import MovieSubscription from '../components/MovieSubscription';
//import Login from './Login';
import Login from '../components/LoginDataProvider';
//import Register from './Register';
import Register from '../components/registerDataProvider';
import Signup from './Signup';
import CancelSubscription from './CancelSubscription';
import SubscriptionSuccessful from './SubscriptionSuccessful';
import Subscription from './Subscription';
import Subscribe from './Subscribe';
import BuyTicketDetails from './BuyTicketDetails';
import AllMovies from './AllMovies/AllMovies';
import Terms from "../containers/Terms";
import TermsSubscription from "../containers/termsSubscription";
import Help from "../containers/Help";
import Contact from '../containers/Contact';

import { PrivateRoute } from '../subscription/PrivateRoute';

import TicketForms from './TicketForms';

import Loader from './Loader';
import DriveInTerms from '../containers/DriveInTerms';

const Router = () => (
  <>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact strict path="/movies/:slug" component={SingleMovie} />
        <Route exact strict path="/movies/:slug/details" component={SingleMovie} />
        <Route exact strict path="/movies/:slug/description" component={SingleMovie} />

        <Route exact strict path="/monitoring-app/movie/the-legend-of-inikpi" component={MovieStatus} />

        <Route exact path="/viva-movie/:slug" component={SingleVivaMovie} />
        <Route exact path="/viva-movie/:slug/details" component={SingleVivaMovie} />
        <Route exact path="/viva-movie/:slug/description" component={SingleVivaMovie} />




        <Route exact path="/oopl-movie/:slug" component={SingleOoplMovie} />
        <Route exact path="/oopl-movie/:slug/details" component={SingleOoplMovie} />
        <Route exact path="/oopl-movie/:slug/description" component={SingleOoplMovie} />
        
        <Route path="/moviesubscription" component={MovieSubscription} />
        <Route path="/subscription" component={Subscription} />
        {/* <Route path="/buyticket" component={BuyTicketDetails} /> */}
        {/* <Route path="/buyticket" component={TicketForms} /> */}

        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/signup" component={Signup} />
        <Route path="/cancel-subscription" component={CancelSubscription} />
        <Route path="/subscription-success" component={SubscriptionSuccessful} />
        {/* <Route path="/subscribe" component={Subscribe} /> */}
        <PrivateRoute path="/subscribe" component={Subscribe} />
        <Route exact path="/movies" component={AllMovies} />
        <Route path="/terms" component={Terms} />
        <Route path="/terms-subscription" component={TermsSubscription} />
        <Route path="/drive-in-terms" component={DriveInTerms} />
        <Route path="/contact" component={Contact} />
        <Route path="/help" component={Help} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
    <Loader />
  </>
);

export default Router;