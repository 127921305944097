import axios from 'axios';
import globals from '../../globals';
import { LOGIN_PENDING, LOGIN_SUCCESS, LOGIN_FAILED, LOADING_START, LOADING_END } from '../actions/types';

const authPath = '/auth/user';
const authUrl = `${globals.api}${authPath}`;
const authUrl2 = `https://movie-visa.herokuapp.com/api/v1/user/login`


export function login(userObj) {
    return async (dispatch) => {
        dispatch({ type: LOGIN_PENDING });
        dispatch({ type: LOADING_START, loadingText: ' ... logging you in... ' })
        console.log('userObj in login action: ', userObj)

        let res;
        try {
            res = await axios.post(authUrl2, userObj); //headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            console.log('logged in successfully: ', res);
            dispatch({ type: LOADING_END })
            dispatch({ type: LOGIN_SUCCESS, payload: res.data });
        } catch (error) {
            console.log('Problem logging in. Error: ', error.message);
            console.log(error);
            dispatch({ type: LOADING_END })
            dispatch({ type: LOGIN_FAILED, payload: error });
        }
    }
}