import { GET_MOVIES_FILTER_PENDING, GET_MOVIES_FILTER_SUCCESS, GET_MOVIES_FILTER_FAILED, GET_MOVIES_FEATURED } from '../actions/types';
//GET_MOVIES_FILTER_PENDING, GET_MOVIES_FILTER_SUCCESS, GET_MOVIES_FILTER_FAILED,
import { uniqueObjects } from '../../helpers'
let initialState = { fetching: false, fetched: false, movies: [] };

export default function moviesFilterReducer(state = initialState, action) {
    switch (action.type) {
        case GET_MOVIES_FILTER_PENDING:
            return { ...state, fetching: true, fetched: false };
        case GET_MOVIES_FILTER_SUCCESS:
            // let movies = state.movies.concat(action.payload)
            console.log('action.payload: ', action.payload)
            return { ...state, movies: action.payload, fetching: false, fetched: true };
        case GET_MOVIES_FILTER_FAILED:
            return { ...state, fetching: false, fetched: false };
        default: return { ...state };
    }
}
