import React, { Component } from 'react'

class CancelSubscription extends Component {
  render() {
    return (
      <div className="login">
        <div className="row my-4">
          <a href="/"><img src="../assets/images/nairabox-logo.png" alt="Nairabox Logo" /></a>
        </div>

        <div className="row my-4 p-4 authpages">
          <div className="col-md-6 login__form">
            <div className="sorry-text green-color">WE'RE SORRY SEE YOU GO</div>
            <div className="signup-text">
              After clicking the button below, your subscription will be cancelled
              and you will not be billed again, you will still be able to log in
              until 6/3/2019, but after this date you account will be disabled.
            </div>
            <div className="row">
              <div className="col-md-12 login__form-input">
                <button className="ticket-button">Cancel my subscription</button>
              </div>
            </div>
          </div>

          <div className="col-md-6 login__side">
            <div className="login__side-content">
              <img src="../assets/images/undraw_movie_night_93wl.png" alt="Login"/>
              <div className="login__lead">All your movies for a small price</div>
              <div className="">This subscription allows you to access any movie of your choice</div>
            </div>
          </div>
        </div>

        <div className="row my-4"><img src="../assets/images/powered_by.png" alt="Powered by VISA"/></div>
      </div>
    )
  }
}

export default CancelSubscription;