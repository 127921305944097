import {
    GET_MOVIES_FILTER_BYCINEMA_PENDING,
    GET_MOVIES_FILTER_BYCINEMA_SUCCESS,
    GET_MOVIES_FILTER_BYCINEMA_FAILED
} from '../actions/types';

let initialState = { fetching: false, fetched: false, movies: [] };

export default function moviesFilterByCinemaReducer(state = initialState, action) {
    switch (action.type) {
        case GET_MOVIES_FILTER_BYCINEMA_PENDING:
            return { ...state, fetching: true, fetched: false };
        case GET_MOVIES_FILTER_BYCINEMA_SUCCESS:
            // let movies = state.movies.concat(action.payload)
            console.log('action.payload: ', action.payload)
            return { ...state, movies: action.payload, fetching: false, fetched: true };
        case GET_MOVIES_FILTER_BYCINEMA_FAILED:
            return { ...state, fetching: false, fetched: false };
        default: return { ...state };
    }
}